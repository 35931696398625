import React, { useState, useEffect } from 'react'

export const Calculator = () => {
	const [val, setVal] = useState({
		principalPerMonth: '',
		totalInterest: '',
		interests: [],
		totalToPay: '',
		duration: '',
	})
	const handleChange = () => {
		const duration = document.getElementById('duration').value
		const amount = document.getElementById('amount').value
		if (duration && amount) {
			const principalPerMonth = amount / duration
			let finalAmount = amount
			const interests = []
			do {
				interests[interests.length] = (0.05 * finalAmount).toFixed(0)
				finalAmount = finalAmount - principalPerMonth
			} while (finalAmount > 0)
			const totalInterest = interests.reduce((a, b) => a + Number(b), 0)
			const totalCapital = principalPerMonth * duration
			const totalToPay = totalCapital + totalInterest
			setVal({
				principalPerMonth: principalPerMonth.toFixed(0),
				totalInterest,
				interests,
				totalToPay,
				duration,
			})
		}
	}
	useEffect(() => {
		handleChange()
	}, [])
	return (
		<section className='pt-10 pt-md-12' id='calculator'>
			<div className='container-lg'>
				<div className='row align-items-center justify-content-between'>
					<div className='col-md-5'>
						{/* Card */}
						<div className='position-relative' data-aos='fade-right'>
							{/* Decoration */}
							<div className='position-absolute top-center top-md-start text-warning mt-n8 ms-md-n8'>
								<svg
									width='278'
									height='278'
									viewBox='0 0 278 278'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path fill='currentColor' d='M3 0h2v8H3z' />
									<path fill='currentColor' d='M0 3h8v2H0zM33 0h2v8h-2z' />
									<path fill='currentColor' d='M30 3h8v2h-8zM63 0h2v8h-2z' />
									<path fill='currentColor' d='M60 3h8v2h-8zM93 0h2v8h-2z' />
									<path fill='currentColor' d='M90 3h8v2h-8zM123 0h2v8h-2z' />
									<path fill='currentColor' d='M120 3h8v2h-8zM153 0h2v8h-2z' />
									<path fill='currentColor' d='M150 3h8v2h-8zM183 0h2v8h-2z' />
									<path fill='currentColor' d='M180 3h8v2h-8zM213 0h2v8h-2z' />
									<path fill='currentColor' d='M210 3h8v2h-8zM243 0h2v8h-2z' />
									<path fill='currentColor' d='M240 3h8v2h-8zM273 0h2v8h-2z' />
									<path fill='currentColor' d='M270 3h8v2h-8zM3 30h2v8H3z' />
									<path fill='currentColor' d='M0 33h8v2H0zM33 30h2v8h-2z' />
									<path fill='currentColor' d='M30 33h8v2h-8zM63 30h2v8h-2z' />
									<path fill='currentColor' d='M60 33h8v2h-8zM93 30h2v8h-2z' />
									<path fill='currentColor' d='M90 33h8v2h-8zM123 30h2v8h-2z' />
									<path
										fill='currentColor'
										d='M120 33h8v2h-8zM153 30h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 33h8v2h-8zM183 30h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 33h8v2h-8zM213 30h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 33h8v2h-8zM243 30h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 33h8v2h-8zM273 30h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 33h8v2h-8zM3 60h2v8H3z' />
									<path fill='currentColor' d='M0 63h8v2H0zM33 60h2v8h-2z' />
									<path fill='currentColor' d='M30 63h8v2h-8zM63 60h2v8h-2z' />
									<path fill='currentColor' d='M60 63h8v2h-8zM93 60h2v8h-2z' />
									<path fill='currentColor' d='M90 63h8v2h-8zM123 60h2v8h-2z' />
									<path
										fill='currentColor'
										d='M120 63h8v2h-8zM153 60h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 63h8v2h-8zM183 60h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 63h8v2h-8zM213 60h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 63h8v2h-8zM243 60h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 63h8v2h-8zM273 60h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 63h8v2h-8zM3 90h2v8H3z' />
									<path fill='currentColor' d='M0 93h8v2H0zM33 90h2v8h-2z' />
									<path fill='currentColor' d='M30 93h8v2h-8zM63 90h2v8h-2z' />
									<path fill='currentColor' d='M60 93h8v2h-8zM93 90h2v8h-2z' />
									<path fill='currentColor' d='M90 93h8v2h-8zM123 90h2v8h-2z' />
									<path
										fill='currentColor'
										d='M120 93h8v2h-8zM153 90h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 93h8v2h-8zM183 90h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 93h8v2h-8zM213 90h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 93h8v2h-8zM243 90h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 93h8v2h-8zM273 90h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 93h8v2h-8zM3 120h2v8H3z' />
									<path fill='currentColor' d='M0 123h8v2H0zM33 120h2v8h-2z' />
									<path
										fill='currentColor'
										d='M30 123h8v2h-8zM63 120h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M60 123h8v2h-8zM93 120h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M90 123h8v2h-8zM123 120h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M120 123h8v2h-8zM153 120h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 123h8v2h-8zM183 120h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 123h8v2h-8zM213 120h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 123h8v2h-8zM243 120h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 123h8v2h-8zM273 120h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 123h8v2h-8zM3 150h2v8H3z' />
									<path fill='currentColor' d='M0 153h8v2H0zM33 150h2v8h-2z' />
									<path
										fill='currentColor'
										d='M30 153h8v2h-8zM63 150h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M60 153h8v2h-8zM93 150h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M90 153h8v2h-8zM123 150h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M120 153h8v2h-8zM153 150h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 153h8v2h-8zM183 150h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 153h8v2h-8zM213 150h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 153h8v2h-8zM243 150h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 153h8v2h-8zM273 150h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 153h8v2h-8zM3 180h2v8H3z' />
									<path fill='currentColor' d='M0 183h8v2H0zM33 180h2v8h-2z' />
									<path
										fill='currentColor'
										d='M30 183h8v2h-8zM63 180h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M60 183h8v2h-8zM93 180h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M90 183h8v2h-8zM123 180h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M120 183h8v2h-8zM153 180h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 183h8v2h-8zM183 180h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 183h8v2h-8zM213 180h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 183h8v2h-8zM243 180h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 183h8v2h-8zM273 180h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 183h8v2h-8zM3 210h2v8H3z' />
									<path fill='currentColor' d='M0 213h8v2H0zM33 210h2v8h-2z' />
									<path
										fill='currentColor'
										d='M30 213h8v2h-8zM63 210h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M60 213h8v2h-8zM93 210h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M90 213h8v2h-8zM123 210h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M120 213h8v2h-8zM153 210h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 213h8v2h-8zM183 210h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 213h8v2h-8zM213 210h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 213h8v2h-8zM243 210h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 213h8v2h-8zM273 210h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 213h8v2h-8zM3 240h2v8H3z' />
									<path fill='currentColor' d='M0 243h8v2H0zM33 240h2v8h-2z' />
									<path
										fill='currentColor'
										d='M30 243h8v2h-8zM63 240h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M60 243h8v2h-8zM93 240h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M90 243h8v2h-8zM123 240h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M120 243h8v2h-8zM153 240h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 243h8v2h-8zM183 240h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 243h8v2h-8zM213 240h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 243h8v2h-8zM243 240h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 243h8v2h-8zM273 240h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 243h8v2h-8zM3 270h2v8H3z' />
									<path fill='currentColor' d='M0 273h8v2H0zM33 270h2v8h-2z' />
									<path
										fill='currentColor'
										d='M30 273h8v2h-8zM63 270h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M60 273h8v2h-8zM93 270h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M90 273h8v2h-8zM123 270h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M120 273h8v2h-8zM153 270h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M150 273h8v2h-8zM183 270h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M180 273h8v2h-8zM213 270h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M210 273h8v2h-8zM243 270h2v8h-2z'
									/>
									<path
										fill='currentColor'
										d='M240 273h8v2h-8zM273 270h2v8h-2z'
									/>
									<path fill='currentColor' d='M270 273h8v2h-8z' />
								</svg>{' '}
							</div>

							{/* Card */}
							<div className='card rounded-bottom-end border-top-3 border-primary mb-8 mb-md-0'>
								<div className='card-body'>
									{/* List group */}
									<div className='list-group list-group-flush my-n5'>
										<div className='list-group-item'>
											{/* Number */}
											<div className='display-4 fw-bolder'>
												<span>&#8358;</span>{' '}
												{Number(val.principalPerMonth).toLocaleString()}
											</div>

											{/* Text */}
											<div className='text-muted'>
												principal payment for {val.duration} months
											</div>
										</div>
										<div className='list-group-item'>
											{/* Number */}
											<div className='display-4 fw-bolder'>
												<span>&#8358;</span>{' '}
												{val.totalInterest.toLocaleString()}
											</div>

											{/* Text */}
											<div className='text-muted'>total interest</div>
										</div>
										<div className='list-group-item'>
											{/* Number */}
											<div className='display-4 fw-bolder text-primary'>
												<span>&#8358;</span> {val.totalToPay.toLocaleString()}
											</div>

											{/* Text */}
											<div className='text-muted'>Total repayment amount</div>
											<div className='text-muted fs-xs'>
												**Figures are estimates**
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className='col-md-6 col-lg-5 text-center text-md-start'
						data-aos='fade-left'
					>
						{/* Heading */}
						<h2 className='display-4 mb-4'>
							Borrowing from NOLT is{' '}
							<span className='text-underline-warning'>Fast</span> and{' '}
							<span className='text-underline-warning'>Efficient</span>
						</h2>

						{/* Text */}
						<p className='text-muted'>
							Fast decision-making enables you to get your cash on the same day
							in some cases. Use the calculator to find out your interest.
						</p>

						{/* Lists */}
						<div className='row'>
							<div className='col-6'>
								<div className='form-group'>
									<label className='' htmlFor='amount'>
										Amount
									</label>
									<input
										className='form-control'
										id='amount'
										name='amount'
										type='number'
										defaultValue='100000'
										placeholder='Amount'
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className='col-6'>
								<div className='form-group'>
									<label className='' htmlFor='duration'>
										Duration (months)
									</label>
									<input
										className='form-control'
										id='duration'
										name='duration'
										defaultValue='6'
										type='number'
										placeholder='Duration'
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
