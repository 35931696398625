import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'

export const Contact = () => {
	const form = useRef()
	const handleSubmit = (e) => {
		e.preventDefault()
		emailjs
			.sendForm(
				'service_j5adiml',
				'template_ddgi0bw',
				form.current,
				'user_vMKdJblR6ftWTka9NgjhZ',
			)
			.then(
				(result) => {
					console.log(result.text)
				},
				(error) => {
					console.log(error.text)
				},
			)
	}
	return (
		<>
			{/* CONTENT */}
			<section className='pt-6 pt-md-6 pb-10 pb-md-12'>
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-8 text-center'>
							{/* Preheading */}
							<h6 className='text-uppercase text-primary mb-5'>Contact us</h6>

							{/* Heading */}
							<h1 className='display-3 mb-4'>How can we help you?</h1>

							{/* Text */}
							<p className='fs-lg text-muted mb-9'>
							Thank you for your interest in NOLT, kindly fill the form below and we would get back to you within 24 hours. We can’t wait to hear from you.
						<br/><br/>	<b>Address:</b> Suite 4, 1 Awolowo road Ikoyi, Lagos state, Nigeria

							</p>
							{/* Items */}
							<div className='row mb-9'>
								<div className='col-md py-md-4 mb-6 mb-md-0'>
									{/* Chat */}
									<a className='text-reset text-decoration-none' href='#!'>
										{/* Icon */}
										<div className='icon text-primary-light mb-3'>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												xmlns='http://www.w3.org/2000/svg'
											>
												<g fill='none' fillRule='evenodd'>
													<path d='M0 0h24v24H0z' />
													<path
														d='M14.486 18l-1.689 3.057a1 1 0 01-1.708.069L9.017 18H5a3 3 0 01-3-3V6a3 3 0 013-3h14a3 3 0 013 3v9a3 3 0 01-3 3h-4.514z'
														fill='#335EEA'
														opacity='.3'
													/>
													<path
														d='M6 7h9a1 1 0 010 2H6a1 1 0 110-2zm0 4h5a1 1 0 010 2H6a1 1 0 010-2z'
														fill='#335EEA'
														opacity='.3'
													/>
												</g>
											</svg>{' '}
										</div>

										{/* Heading */}
										<h6 className='text-uppercase mb-0'>Live chat</h6>

										{/* Text */}
										<small className='text-muted'>
											Feature comming soon!
										</small>
									</a>
								</div>
								<div className='col-md py-md-4 mb-6 mb-md-0 border-start-md'>
									{/* Chat */}
									<a
										className='text-reset text-decoration-none'
										href='mailto:customercare@noltfinance.com'
									>
										{/* Icon */}
										<div className='icon text-primary-light mb-3'>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												xmlns='http://www.w3.org/2000/svg'
											>
												<g fill='none' fillRule='evenodd'>
													<path d='M0 0h24v24H0z' />
													<path
														d='M6 2h12a1 1 0 011 1v9a1 1 0 01-1 1H6a1 1 0 01-1-1V3a1 1 0 011-1zm1.5 3a.5.5 0 000 1h6a.5.5 0 100-1h-6zm0 2a.5.5 0 000 1h3a.5.5 0 100-1h-3z'
														fill='#335EEA'
														opacity='.3'
													/>
													<path
														d='M3.793 6.573L12 12.5l8.207-5.927a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405z'
														fill='#335EEA'
													/>
												</g>
											</svg>{' '}
										</div>

										{/* Heading */}
										<h6 className='text-uppercase mb-0'>Email us</h6>

										{/* Text */}
										<small className='text-muted'>We reply in ~24 hours</small>
									</a>
								</div>
								<div className='col-md py-md-4 border-start-md'>
									{/* Chat */}
									<a className='text-reset text-decoration-none' href='tel:#!'>
										{/* Icon */}
										<div className='icon text-primary-light mb-3'>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												xmlns='http://www.w3.org/2000/svg'
											>
												<g fill='none' fillRule='evenodd'>
													<path d='M0 0h24v24H0z' />
													<path
														d='M13.08 14.784l2.204-2.204a2 2 0 00.375-2.309l-.125-.25a2 2 0 01.374-2.308l2.733-2.733a.5.5 0 01.801.13l1.104 2.208a4.387 4.387 0 01-.822 5.065l-5.999 5.998a5.427 5.427 0 01-5.553 1.311l-2.415-.804a.5.5 0 01-.195-.828l2.65-2.652a2 2 0 012.31-.374l.25.125a2 2 0 002.308-.375z'
														fill='#335EEA'
													/>
													<path
														d='M14.148 6.007l-.191 1.991a4.987 4.987 0 00-4.018 1.441 4.987 4.987 0 00-1.442 4.004l-1.992.185a6.986 6.986 0 012.02-5.603 6.987 6.987 0 015.623-2.018zm.35-3.985l-.185 1.992A8.978 8.978 0 007.111 6.61a8.978 8.978 0 00-2.598 7.191l-1.992.183a10.977 10.977 0 013.176-8.788 10.977 10.977 0 018.801-3.175z'
														fill='#335EEA'
														opacity='.3'
													/>
												</g>
											</svg>{' '}
										</div>

										{/* Heading */}
										<h6 className='text-uppercase mb-0'>Call</h6>

										{/* Text */}
										<small className='text-muted'>+234 814 922 0557</small>
									</a>
								</div>
							</div>

							{/* Form */}
							<form ref={form} onSubmit={handleSubmit}>
								<div className='row'>
									<div className='col-md-6'>
										{/* Name */}
										<div className='form-group'>
											<label className='visually-hidden' htmlFor='contactName'>
												Your name
											</label>
											<input
												className='form-control'
												id='contactName'
												type='text'
												placeholder='Your name'
												name='name'
											/>
										</div>
									</div>
									<div className='col-md-6'>
										{/* Email */}
										<div className='form-group'>
											<label className='visually-hidden' htmlFor='contactEmail'>
												Your email
											</label>
											<input
												className='form-control'
												id='contactEmail'
												type='email'
												placeholder='Your email'
												required
												name='email'
											/>
										</div>
									</div>
									<div className='col-12'>
										{/* Message */}
										<div className='form-group'>
											<label
												className='visually-hidden'
												htmlFor='contactMessage'
											>
												Your message
											</label>
											<textarea
												className='form-control'
												id='contactMessage'
												placeholder='Your message'
												rows='7'
												name='message'
											></textarea>
										</div>
									</div>
								</div>
								<button className='btn w-100 btn-primary'>
									Send a Message
								</button>
							</form>
						</div>
					</div>
				</div>
			</section>
			
				{/* CTA */}
			<section className='bg-dark border-multicolor'>
				<div className='container-lg'>
					<div className='row align-items-center'>
						<div className='col-12'>
							{/* Divider */}
							<hr className='bg-gray-900 mt-0 mb-8' />
						</div>
						<div className='col-md'>
							{/* Heading */}
							<h1 className='text-white mb-0'>
							Ready to get a Loan to meet your needs?{' '}
								<span className='text-primary-light'></span>
							</h1>

							{/* Text */}
							<p className='text-white-60 mb-4 mb-md-0'>
								We’ll walk you through the loan application process. 
							</p>
						</div>
						<div className='col-md-auto'>
							{/* Button */}
							<a className='btn btn-primary lift' href='/invest#loans'>
								Apply Now
							</a>
						</div>
						<div className='col-12'>
							{/* Divider */}
							<hr className='bg-gray-900 mb-0 mt-8' />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
