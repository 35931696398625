import React from 'react'

export const FAQ = () => {
	return (
		<>
			{/* WELCOME */}
			<section className='pt-6 pt-md-11'>
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-9 text-center'>
							{/* Preheading */}
							<h6 className='text-uppercase text-primary mb-5'>FAQs</h6>

							{/* Heading */}
							<h2 className='display-3 mb-4'>Frequently Asked Questions</h2>

							{/* Text */}
							<p className='fs-lg text-muted'>
								Get to know about NOLT Finance before reaching out to us. Ask us anything.
								<br/>No long thing when it comes to your finances
							</p>

							{/* Form */}
						 
						</div>
					</div>
				</div>
			</section>

			{/* CONTENT */}
			<section className='pt-10 pt-md-12'>
				<div className='container-lg'>
					<div className='row'>
						<div className='col'>
							{/* Divider */}
							<hr className='hr-sm bg-warning mx-auto mb-10 mb-md-12' />
						</div>
					</div>
					<div className='row justify-content-between'>
						<div className='col-md-4'>
							{/* Header */}
							<div className='position-sticky mb-8' style={{ top: '2rem' }}>
								{/* Heading */}
								<h1 className='mb-4'>NOLT Finance FAQs</h1>

								{/* Text */}
								<p className='mb-0'>
									NOLT Finance Frequently Asked Questions by our Loan borrowers
								</p>
							</div>
						</div>
						<div className='col-md-7'>
							{/* List group */}
							<div
								className='list-group list-group-lg list-group-flush mt-n7 mb-9'
								id='faqData'
							>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqDataOne'
										role='button'
										aria-expanded='true'
										aria-controls='faqDataOne'
									>
										After Bank refusal, can I still apply for a NOLT loan?
									</a>

									{/* Collapse */}
									<div
										className='collapse show'
										id='faqDataOne'
										data-bs-parent='#faqData'
									>
										<p className='mt-2 mb-0'>
											The social mission of NOLT is to provide financial inclusion to people who would otherwise be excluded by conventional banks and lending institutions. We would be glad to get your application.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqDataTwo'
										role='button'
										aria-expanded='false'
										aria-controls='faqDataTwo'
									>
										How can I access the loans?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqDataTwo'
										data-bs-parent='#faqData'
									>
										<p className='mt-2 mb-0'>
											You can get a loan from NOLT through a variety of technical terminals - our mobile app, website, as well as offline and online points of sale.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqDataThree'
										role='button'
										aria-expanded='false'
										aria-controls='faqDataThree'
									>
										How soon after applying can I get my money?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqDataThree'
										data-bs-parent='#faqData'
									>
										<p className='mt-2 mb-0'>
											A substantial number of consumers are accustomed to the lengthy procedures involved in obtaining a loan as a result of decades of conventional lending. However we are here to save the day, our team works hard to ensure getting your money is no long thing, with our short application process.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqDataFour'
										role='button'
										aria-expanded='false'
										aria-controls='faqDataFour'
									>
										What if I can't find the specific loan I want?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqDataFour'
										data-bs-parent='#faqData'
									>
										<p className='mt-2 mb-0'>
											Our product ranges are focused on technology and decision sciences. We custom-build pocket-friendly finance solutions, please contact us if there is a particular loan you think we could be missing. 
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row justify-content-between'>
						<div className='col-md-4'>
							{/* Header */}
							<div className='position-sticky mb-8' style={{ top: '2rem' }}>
								{/* Heading */}
								<h1 className='mb-4'>Overview</h1>

								{/* Text */}
								<p className='mb-0'>
									General Questions asked by our prospective loan borrower. Read this before reaching out.
								</p>
							</div>
						</div>
						<div className='col-md-7'>
							{/* List group */}
							<div
								className='list-group list-group-lg list-group-flush mt-n7 mb-9'
								id='faqAccount'
							>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqAccountOne'
										role='button'
										aria-expanded='true'
										aria-controls='faqAccountOne'
									>
										Why should you take a business loan?
									</a>

									{/* Collapse */}
									<div
										className='collapse show'
										id='faqAccountOne'
										data-bs-parent='#faqAccount'
									>
										<p className='mt-2 mb-0'>
											You can take a business loan to fund your business investments and projects.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqAccountTwo'
										role='button'
										aria-expanded='false'
										aria-controls='faqAccountTwo'
									>
										Why choose NOLT for a business loan?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqAccountTwo'
										data-bs-parent='#faqAccount'
									>
										<p className='mt-2 mb-0'>
											This depends on accurate submission of KYC documents which will enable us to make an accurate assessment of the application within 48 hours. We have a very fast loan-lending process with three steps. Application submission, Approval and Disbursal.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqAccountThree'
										role='button'
										aria-expanded='false'
										aria-controls='faqAccountThree'
									>
										Do I have to visit the NOLT office to avail a loan?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqAccountThree'
										data-bs-parent='#faqAccount'
									>
										<p className='mt-2 mb-0'>
											This varies based on the amount requested. Generally, the whole process of taking loans is automated online. The document verification too occurs online and thus provides a hassle-free loan application process.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqAccountFour'
										role='button'
										aria-expanded='false'
										aria-controls='faqAccountFour'
									>
										What are the chances of me getting a business loan from NOLT?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqAccountFour'
										data-bs-parent='#faqAccount'
									>
										<p className='mt-2 mb-0'>
											Due to the different ranges of ticket-size we offer, there is a much higher chance of a loan approval from us than a traditional bank.
										</p>
									</div>
								</div>
								
								
										<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqAccountFive'
										role='button'
										aria-expanded='false'
										aria-controls='faqAccountFive'
									>
										How can I speed up my loan approval?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqAccountFive'
										data-bs-parent='#faqAccount'
									>
										<p className='mt-2 mb-0'>
By providing correct documents and following the application process thoroughly so that the loan approval doesn’t have any stoppages.										</p>
									</div>
								</div>
								
								
										<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqAccountSix'
										role='button'
										aria-expanded='false'
										aria-controls='faqAccountSix'
									>
										How do I repay a NOLT loan?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqAccountSix'
										data-bs-parent='#faqAccount'
									>
										<p className='mt-2 mb-0'>
										All repayments can be made either via bank transfer or post-dated cheque.
										</p>
									</div>
								</div>
								
								
										<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqAccountSeven'
										role='button'
										aria-expanded='false'
										aria-controls='faqAccountSeven'
									>
										Can I repay my loan before the due date?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqAccountSeven'
										data-bs-parent='#faqAccount'
									>
										<p className='mt-2 mb-0'>
Yes										</p>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div className='row justify-content-between'>
						<div className='col-md-4'>
							{/* Header */}
							<div className='position-sticky mb-8' style={{ top: '2rem' }}>
								{/* Heading */}
								<h1 className='mb-4'>Eligibility</h1>

								{/* Text */}
								<p className='mb-0'>
									These questions will provide you with the relevant eligibility screening before seeking for loan.
								</p>
							</div>
						</div>
						<div className='col-md-7'>
							{/* List group */}
							<div
								className='list-group list-group-lg list-group-flush mt-n7 mb-0'
								id='faqBilling'
							>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqBillingOne'
										role='button'
										aria-expanded='true'
										aria-controls='faqBillingOne'
									>
										What kinds of businesses does NOLT lend to?
									</a>

									{/* Collapse */}
									<div
										className='collapse show'
										id='faqBillingOne'
										data-bs-parent='#faqBilling'
									>
										<p className='mt-2 mb-0'>
											We work with small & medium enterprises across all industries.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqBillingTwo'
										role='button'
										aria-expanded='false'
										aria-controls='faqBillingTwo'
									>
										How do I find out if I’m eligible?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqBillingTwo'
										data-bs-parent='#faqBilling'
									>
										<p className='mt-2 mb-0'>
											The eligibility for our loans varies from product to product. Visit our product pages for more information.
										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqBillingThree'
										role='button'
										aria-expanded='false'
										aria-controls='faqBillingThree'
									>
										 Can I still get a loan for a Business that is less than a year old?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqBillingThree'
										data-bs-parent='#faqBilling'
									>
										<p className='mt-2 mb-0'>
							Your business must have been in operation for at least one year.

										</p>
									</div>
								</div>
								<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqBillingFour'
										role='button'
										aria-expanded='false'
										aria-controls='faqBillingFour'
									>
										What documents should I submit for a Business Loan?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqBillingFour'
										data-bs-parent='#faqBilling'
									>
										<p className='mt-2 mb-0'>
											1. KYC Documents of the Proprietor / Partner / Director as applicable and KYC documents of co-applicants
<br/>2. KYC Documents of the Entity (Firm / Pvt Ltd. Co. as applicable)
<br/>3. Income documents (Financials/management account for at least 1 year)
<br/>4. Business continuity proof
<br/>5. 1 year bank statement downloaded via net banking
<br/>6. Utility bills (LAWMA, PHCN, Water)
										</p>
									</div>
								</div>
								
								
									<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqBillingFive'
										role='button'
										aria-expanded='false'
										aria-controls='faqBillingFive'
									>
										What documents shall be considered as a valid Identity Proof?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqBillingFive'
										data-bs-parent='#faqBilling'
									>
										<p className='mt-2 mb-0'>
										1. International Passport
<br/>2. Election / Voter’s ID card
<br/>3. Driver’s License
<br/>4. National Identity Card (NIMC)
										</p>
									</div>
								</div>
								
								
									<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqBillingSix'
										role='button'
										aria-expanded='false'
										aria-controls='faqBillingSix'
									>
										What documents shall be considered as a valid Address Proof?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqBillingSix'
										data-bs-parent='#faqBilling'
									>
										<p className='mt-2 mb-0'>
										 1. Utility Bill (LAWMA, PHCN, Water)
<br/>2. House Rent Receipt
										</p>
									</div>
								</div>
								
									<div className='list-group-item'>
									{/* Heading */}
									<a
										className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
										data-bs-toggle='collapse'
										href='#faqBillingSeven'
										role='button'
										aria-expanded='false'
										aria-controls='faqBillingSeven'
									>
										I registered my business this year, but have been running it for a long time. Will my application be considered as eligible?
									</a>

									{/* Collapse */}
									<div
										className='collapse'
										id='faqBillingSeven'
										data-bs-parent='#faqBilling'
									>
										<p className='mt-2 mb-0'>
										 Yes, subject to verification
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* OPTIONS */}
		 
		</>
	)
}
