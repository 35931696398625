import React from 'react'
import { Link } from 'react-router-dom'
import { Calculator } from '../components/Calculator'

export const Home = () => {
	return (
		<>
			{/* WELCOME */}
			{/* WELCOME */}
			<section className='pt-6 pt-md-8 pb-8 pb-md-9 bg-light'>
				<div className='container-lg'>
					<div className='row align-items-center'>
						<div className='col-md-9 offset-md-n3 order-md-1'>
							{/* Image */}
							<img
								className='img-fluid mw-md-125 mb-8 mb-md-0'
								src='assets/img/homeLoan2.jpeg'
								alt='...'
								data-aos='fade-up'
								data-aos-delay='100'
								width='550'
								style={{ float: 'right', marginLeft: '20px' }}
							/>
						</div>
						<div
							className='col-md-6 order-md-0 text-center text-md-start'
							data-aos='fade-up'
						>
							{/* Heading */}
							<h1 className='display-3 mb-4'>
								Empower your financial journey <br />
								<span className='text-primary-light'></span>
							</h1>

							{/* Text */}
							<p className='fs-lg'>
								Every individual should have the power to access the financial
								goals of their dreams. Nolt provides{' '}
								<div className='d-none'>
									<br />
								</div>
								investments and low interest lending solutions{' '}
								<div className='d-none'>
									<br />
								</div>{' '}
								to keep you truly covered.
							</p>

							{/* Buttons */}
							<Link
								className='btn btn-primary-light lift'
								data-bs-toggle='modal'
								data-bs-target='#calculatorModal'
							>
								Get Started
							</Link>
							<a className='btn btn-white lift ms-3' href='/invest#loans'>
								Learn More
							</a>
						</div>
					</div>
				</div>
			</section>

			{/* SHAPE */}
			<div className='position-relative'>
				<div className='shape shape-fluid-x shape-bottom text-light pb-15 pb-md-4'>
					<div className='shape-img pb-9 pb-md-15'>
						<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
							<path d='M0 0h100v25H75L25 50H0z' fill='currentColor' />
						</svg>{' '}
					</div>
				</div>
			</div>

			{/* ABOUT */}

			{/* ABOUT */}
			<section>
				<div className='container-lg position-relative' style={{ zIndex: 1 }}>
					<div className='row align-items-center justify-content-between'>
						<div className='col-md-5 col-lg-4 pt-10 pt-md-12'>
							{/* Image */}
							<div className='position-relative'>
								{/* Decoration */}
								<div className='position-absolute top-end text-warning mt-n8 me-n8'>
									<svg
										width='1855'
										height='186'
										viewBox='0 0 185 186'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<circle cx='2' cy='2' r='2' fill='currentColor' />
										<circle cx='22' cy='2' r='2' fill='currentColor' />
										<circle cx='42' cy='2' r='2' fill='currentColor' />
										<circle cx='62' cy='2' r='2' fill='currentColor' />
										<circle cx='82' cy='2' r='2' fill='currentColor' />
										<circle cx='102' cy='2' r='2' fill='currentColor' />
										<circle cx='122' cy='2' r='2' fill='currentColor' />
										<circle cx='142' cy='2' r='2' fill='currentColor' />
										<circle cx='162' cy='2' r='2' fill='currentColor' />
										<circle cx='182' cy='2' r='2' fill='currentColor' />
										<circle cx='2' cy='22' r='2' fill='currentColor' />
										<circle cx='22' cy='22' r='2' fill='currentColor' />
										<circle cx='42' cy='22' r='2' fill='currentColor' />
										<circle cx='62' cy='22' r='2' fill='currentColor' />
										<circle cx='82' cy='22' r='2' fill='currentColor' />
										<circle cx='102' cy='22' r='2' fill='currentColor' />
										<circle cx='122' cy='22' r='2' fill='currentColor' />
										<circle cx='142' cy='22' r='2' fill='currentColor' />
										<circle cx='162' cy='22' r='2' fill='currentColor' />
										<circle cx='182' cy='22' r='2' fill='currentColor' />
										<circle cx='2' cy='42' r='2' fill='currentColor' />
										<circle cx='22' cy='42' r='2' fill='currentColor' />
										<circle cx='42' cy='42' r='2' fill='currentColor' />
										<circle cx='62' cy='42' r='2' fill='currentColor' />
										<circle cx='82' cy='42' r='2' fill='currentColor' />
										<circle cx='102' cy='42' r='2' fill='currentColor' />
										<circle cx='122' cy='42' r='2' fill='currentColor' />
										<circle cx='142' cy='42' r='2' fill='currentColor' />
										<circle cx='162' cy='42' r='2' fill='currentColor' />
										<circle cx='182' cy='42' r='2' fill='currentColor' />
										<circle cx='2' cy='62' r='2' fill='currentColor' />
										<circle cx='22' cy='62' r='2' fill='currentColor' />
										<circle cx='42' cy='62' r='2' fill='currentColor' />
										<circle cx='62' cy='62' r='2' fill='currentColor' />
										<circle cx='82' cy='62' r='2' fill='currentColor' />
										<circle cx='102' cy='62' r='2' fill='currentColor' />
										<circle cx='122' cy='62' r='2' fill='currentColor' />
										<circle cx='142' cy='62' r='2' fill='currentColor' />
										<circle cx='162' cy='62' r='2' fill='currentColor' />
										<circle cx='182' cy='62' r='2' fill='currentColor' />
										<circle cx='2' cy='82' r='2' fill='currentColor' />
										<circle cx='22' cy='82' r='2' fill='currentColor' />
										<circle cx='42' cy='82' r='2' fill='currentColor' />
										<circle cx='62' cy='82' r='2' fill='currentColor' />
										<circle cx='82' cy='82' r='2' fill='currentColor' />
										<circle cx='102' cy='82' r='2' fill='currentColor' />
										<circle cx='122' cy='82' r='2' fill='currentColor' />
										<circle cx='142' cy='82' r='2' fill='currentColor' />
										<circle cx='162' cy='82' r='2' fill='currentColor' />
										<circle cx='182' cy='82' r='2' fill='currentColor' />
										<circle cx='2' cy='102' r='2' fill='currentColor' />
										<circle cx='22' cy='102' r='2' fill='currentColor' />
										<circle cx='42' cy='102' r='2' fill='currentColor' />
										<circle cx='62' cy='102' r='2' fill='currentColor' />
										<circle cx='82' cy='102' r='2' fill='currentColor' />
										<circle cx='102' cy='102' r='2' fill='currentColor' />
										<circle cx='122' cy='102' r='2' fill='currentColor' />
										<circle cx='142' cy='102' r='2' fill='currentColor' />
										<circle cx='162' cy='102' r='2' fill='currentColor' />
										<circle cx='182' cy='102' r='2' fill='currentColor' />
										<circle cx='2' cy='122' r='2' fill='currentColor' />
										<circle cx='22' cy='122' r='2' fill='currentColor' />
										<circle cx='42' cy='122' r='2' fill='currentColor' />
										<circle cx='62' cy='122' r='2' fill='currentColor' />
										<circle cx='82' cy='122' r='2' fill='currentColor' />
										<circle cx='102' cy='122' r='2' fill='currentColor' />
										<circle cx='122' cy='122' r='2' fill='currentColor' />
										<circle cx='142' cy='122' r='2' fill='currentColor' />
										<circle cx='162' cy='122' r='2' fill='currentColor' />
										<circle cx='182' cy='122' r='2' fill='currentColor' />
										<circle cx='2' cy='142' r='2' fill='currentColor' />
										<circle cx='22' cy='142' r='2' fill='currentColor' />
										<circle cx='42' cy='142' r='2' fill='currentColor' />
										<circle cx='62' cy='142' r='2' fill='currentColor' />
										<circle cx='82' cy='142' r='2' fill='currentColor' />
										<circle cx='102' cy='142' r='2' fill='currentColor' />
										<circle cx='122' cy='142' r='2' fill='currentColor' />
										<circle cx='142' cy='142' r='2' fill='currentColor' />
										<circle cx='162' cy='142' r='2' fill='currentColor' />
										<circle cx='182' cy='142' r='2' fill='currentColor' />
										<circle cx='2' cy='162' r='2' fill='currentColor' />
										<circle cx='22' cy='162' r='2' fill='currentColor' />
										<circle cx='42' cy='162' r='2' fill='currentColor' />
										<circle cx='62' cy='162' r='2' fill='currentColor' />
										<circle cx='82' cy='162' r='2' fill='currentColor' />
										<circle cx='102' cy='162' r='2' fill='currentColor' />
										<circle cx='122' cy='162' r='2' fill='currentColor' />
										<circle cx='142' cy='162' r='2' fill='currentColor' />
										<circle cx='162' cy='162' r='2' fill='currentColor' />
										<circle cx='182' cy='162' r='2' fill='currentColor' />
										<circle cx='2' cy='182' r='2' fill='currentColor' />
										<circle cx='22' cy='182' r='2' fill='currentColor' />
										<circle cx='42' cy='182' r='2' fill='currentColor' />
										<circle cx='62' cy='182' r='2' fill='currentColor' />
										<circle cx='82' cy='182' r='2' fill='currentColor' />
										<circle cx='102' cy='182' r='2' fill='currentColor' />
										<circle cx='122' cy='182' r='2' fill='currentColor' />
										<circle cx='142' cy='182' r='2' fill='currentColor' />
										<circle cx='162' cy='182' r='2' fill='currentColor' />
										<circle cx='182' cy='182' r='2' fill='currentColor' />
									</svg>{' '}
								</div>

								{/* Decoration */}
								<div className='position-absolute bottom-start text-warning ms-n10 mb-8'>
									<svg
										width='185'
										height='186'
										viewBox='0 0 185 186'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<circle cx='2' cy='2' r='2' fill='currentColor' />
										<circle cx='22' cy='2' r='2' fill='currentColor' />
										<circle cx='42' cy='2' r='2' fill='currentColor' />
										<circle cx='62' cy='2' r='2' fill='currentColor' />
										<circle cx='82' cy='2' r='2' fill='currentColor' />
										<circle cx='102' cy='2' r='2' fill='currentColor' />
										<circle cx='122' cy='2' r='2' fill='currentColor' />
										<circle cx='142' cy='2' r='2' fill='currentColor' />
										<circle cx='162' cy='2' r='2' fill='currentColor' />
										<circle cx='182' cy='2' r='2' fill='currentColor' />
										<circle cx='2' cy='22' r='2' fill='currentColor' />
										<circle cx='22' cy='22' r='2' fill='currentColor' />
										<circle cx='42' cy='22' r='2' fill='currentColor' />
										<circle cx='62' cy='22' r='2' fill='currentColor' />
										<circle cx='82' cy='22' r='2' fill='currentColor' />
										<circle cx='102' cy='22' r='2' fill='currentColor' />
										<circle cx='122' cy='22' r='2' fill='currentColor' />
										<circle cx='142' cy='22' r='2' fill='currentColor' />
										<circle cx='162' cy='22' r='2' fill='currentColor' />
										<circle cx='182' cy='22' r='2' fill='currentColor' />
										<circle cx='2' cy='42' r='2' fill='currentColor' />
										<circle cx='22' cy='42' r='2' fill='currentColor' />
										<circle cx='42' cy='42' r='2' fill='currentColor' />
										<circle cx='62' cy='42' r='2' fill='currentColor' />
										<circle cx='82' cy='42' r='2' fill='currentColor' />
										<circle cx='102' cy='42' r='2' fill='currentColor' />
										<circle cx='122' cy='42' r='2' fill='currentColor' />
										<circle cx='142' cy='42' r='2' fill='currentColor' />
										<circle cx='162' cy='42' r='2' fill='currentColor' />
										<circle cx='182' cy='42' r='2' fill='currentColor' />
										<circle cx='2' cy='62' r='2' fill='currentColor' />
										<circle cx='22' cy='62' r='2' fill='currentColor' />
										<circle cx='42' cy='62' r='2' fill='currentColor' />
										<circle cx='62' cy='62' r='2' fill='currentColor' />
										<circle cx='82' cy='62' r='2' fill='currentColor' />
										<circle cx='102' cy='62' r='2' fill='currentColor' />
										<circle cx='122' cy='62' r='2' fill='currentColor' />
										<circle cx='142' cy='62' r='2' fill='currentColor' />
										<circle cx='162' cy='62' r='2' fill='currentColor' />
										<circle cx='182' cy='62' r='2' fill='currentColor' />
										<circle cx='2' cy='82' r='2' fill='currentColor' />
										<circle cx='22' cy='82' r='2' fill='currentColor' />
										<circle cx='42' cy='82' r='2' fill='currentColor' />
										<circle cx='62' cy='82' r='2' fill='currentColor' />
										<circle cx='82' cy='82' r='2' fill='currentColor' />
										<circle cx='102' cy='82' r='2' fill='currentColor' />
										<circle cx='122' cy='82' r='2' fill='currentColor' />
										<circle cx='142' cy='82' r='2' fill='currentColor' />
										<circle cx='162' cy='82' r='2' fill='currentColor' />
										<circle cx='182' cy='82' r='2' fill='currentColor' />
										<circle cx='2' cy='102' r='2' fill='currentColor' />
										<circle cx='22' cy='102' r='2' fill='currentColor' />
										<circle cx='42' cy='102' r='2' fill='currentColor' />
										<circle cx='62' cy='102' r='2' fill='currentColor' />
										<circle cx='82' cy='102' r='2' fill='currentColor' />
										<circle cx='102' cy='102' r='2' fill='currentColor' />
										<circle cx='122' cy='102' r='2' fill='currentColor' />
										<circle cx='142' cy='102' r='2' fill='currentColor' />
										<circle cx='162' cy='102' r='2' fill='currentColor' />
										<circle cx='182' cy='102' r='2' fill='currentColor' />
										<circle cx='2' cy='122' r='2' fill='currentColor' />
										<circle cx='22' cy='122' r='2' fill='currentColor' />
										<circle cx='42' cy='122' r='2' fill='currentColor' />
										<circle cx='62' cy='122' r='2' fill='currentColor' />
										<circle cx='82' cy='122' r='2' fill='currentColor' />
										<circle cx='102' cy='122' r='2' fill='currentColor' />
										<circle cx='122' cy='122' r='2' fill='currentColor' />
										<circle cx='142' cy='122' r='2' fill='currentColor' />
										<circle cx='162' cy='122' r='2' fill='currentColor' />
										<circle cx='182' cy='122' r='2' fill='currentColor' />
										<circle cx='2' cy='142' r='2' fill='currentColor' />
										<circle cx='22' cy='142' r='2' fill='currentColor' />
										<circle cx='42' cy='142' r='2' fill='currentColor' />
										<circle cx='62' cy='142' r='2' fill='currentColor' />
										<circle cx='82' cy='142' r='2' fill='currentColor' />
										<circle cx='102' cy='142' r='2' fill='currentColor' />
										<circle cx='122' cy='142' r='2' fill='currentColor' />
										<circle cx='142' cy='142' r='2' fill='currentColor' />
										<circle cx='162' cy='142' r='2' fill='currentColor' />
										<circle cx='182' cy='142' r='2' fill='currentColor' />
										<circle cx='2' cy='162' r='2' fill='currentColor' />
										<circle cx='22' cy='162' r='2' fill='currentColor' />
										<circle cx='42' cy='162' r='2' fill='currentColor' />
										<circle cx='62' cy='162' r='2' fill='currentColor' />
										<circle cx='82' cy='162' r='2' fill='currentColor' />
										<circle cx='102' cy='162' r='2' fill='currentColor' />
										<circle cx='122' cy='162' r='2' fill='currentColor' />
										<circle cx='142' cy='162' r='2' fill='currentColor' />
										<circle cx='162' cy='162' r='2' fill='currentColor' />
										<circle cx='182' cy='162' r='2' fill='currentColor' />
										<circle cx='2' cy='182' r='2' fill='currentColor' />
										<circle cx='22' cy='182' r='2' fill='currentColor' />
										<circle cx='42' cy='182' r='2' fill='currentColor' />
										<circle cx='62' cy='182' r='2' fill='currentColor' />
										<circle cx='82' cy='182' r='2' fill='currentColor' />
										<circle cx='102' cy='182' r='2' fill='currentColor' />
										<circle cx='122' cy='182' r='2' fill='currentColor' />
										<circle cx='142' cy='182' r='2' fill='currentColor' />
										<circle cx='162' cy='182' r='2' fill='currentColor' />
										<circle cx='182' cy='182' r='2' fill='currentColor' />
									</svg>{' '}
								</div>

								{/* Image */}
								<img
									className='position-relative img-fluid rounded shadow-lg'
									data-jarallax-element='-50'
									src='assets/img/NoltApp.jpeg'
									alt='...'
									width='600px'
								/>
							</div>
						</div>
						<div className='col-md-6 pt-8 pb-10 py-md-12 text-center text-md-start'>
							{/* Heading */}
							<h2 className='display-4 mb-4'>
								Your one-stop shop for investing and lending.
							</h2>

							{/* Text */}
							<p>
								Download the Nolt app to access the finances you need with no
								more hassle or headache.
							</p>

							{/* Buttons */}
							<div className='d-flex flex-nowrap justify-content-center justify-content-md-start'>
								<a
									className='lift'
									href='https://play.google.com/store/apps/details?id=com.noltfinance'
									style={{ maxWidth: '180px' }}
								>
									<img
										className='img-fluid'
										src='assets/img/buttons/button-app.png'
										alt='...'
									/>
								</a>
								<a
									className='lift ms-2'
									href='https://apps.apple.com/ng/app/nolt/id1577549857'
									style={{ maxWidth: '180px' }}
								>
									<img
										className='img-fluid'
										src='assets/img/buttons/button-play.png'
										alt='...'
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* SHAPE */}
			<div className='position-relative'>
				<div className='shape shape-fluid-x shape-top text-white'>
					<div className='shape-img pb-8'>
						<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
							<path d='M0 25h25L75 0h25v50H0z' fill='currentColor' />
						</svg>{' '}
					</div>
				</div>
			</div>

			{/* STEPS */}
			<section className='pt-9 pt-md-11 bg-white'>
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-8 text-center'>
							{/* Preheading */}
							<h6 className='text-uppercase text-primary mb-5'>
								NOLT Finance Process
							</h6>

							{/* Heading */}
							<h2 className='display-4 mb-9'>
								Why Choose Nolt for your <br />
								loans in Nigeria?{' '}
								<span className='text-underline-warning'></span>
							</h2>
						</div>
					</div>
					<div className='roww justify-content-centerw'>
						<div className='col-12w col-md-8w col-lg-12w'>
							<div
								class='row justify-content-center '
								style={{ marginLeft: '100px' }}
							>
								<div class='col-lg-4'>
									<img src='assets/img/weCare.png' alt='logo' width='150px' />

									<h2>Speed</h2>

									{/* Text */}
									<p className='text-muted mb-4'>
										We are committed to responsible lending. We are a seasoned
										team of experts looking after your needs.
									</p>
								</div>
								<div class='col-lg-4'>
									<img src='assets/img/weFast.png' alt='logo' width='150px' />
									{/* Heading */}
									<h2>We are fast</h2>

									{/* Text */}
									<p className='text-muted mb-4'>
										Fast decision-making enables you to get your cash on the
										same day in some cases.
									</p>
								</div>
								<div class='col-lg-4'>
									<img src='assets/img/weKnow.png' alt='logo' width='120px' />
									<h2>We know</h2>

									{/* Text */}
									<p className='text-muted mb-4'>
										Our team is made up of seasoned experts that know exactly
										what they are doing.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* TESTIMONIALS */}
			<section className='position-relative mt-10'>
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-8 text-center'>
							{/* Preheading */}
							<h6 className='text-uppercase text-primary mb-5'>Testimonials</h6>

							{/* Heading */}
							<h2 className='display-4 mb-9'>
								What Nolt Users Say{' '}
								<span className='text-underline-warning'></span>
							</h2>
						</div>
					</div>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-xl-8 position-static'>
							{/* Divider */}
							<hr className='hr-sm bg-warning mx-auto mb-2 mb-md-2' />

							{/* Slider */}
							<div
								className='position-static flickity-soft-edges flickity-buttons-responsive'
								data-flickity='{"wrapAround": true}'
							>
								<div className='col-12 px-4'>
									<blockquote className='text-center mb-0'>
										{/* Preheading */}
										<h6 className='text-uppercase text-warning mb-3 mb-md-5'>
											Keep it simple
										</h6>

										{/* Heading */}
										<h2 className='display-3 mb-5 mb-md-8'>
											<q>
												Very friendly staff and good customer service{' '}
												<span className='text-underline-warning'></span>{' '}
											</q>
										</h2>

										{/* Footer */}
										<footer>
											{/* Avatar */}
											<div className='avatar mb-4'>
												<img
													className='avatar-img rounded-circle'
													src='assets/img/FemiGeorge.png'
													alt='...'
												/>
											</div>

											{/* Heading */}
											<h4 className='font-sans-serif mb-0'>Femi George</h4>

											{/* Position */}
											<p className='fs-sm text-muted mt-n1 mb-0'>
												Loan Borrower
											</p>
										</footer>
									</blockquote>
								</div>
								<div className='col-12 px-4'>
									<blockquote className='text-center mb-0'>
										{/* Preheading */}
										<h6 className='text-uppercase text-warning mb-3 mb-md-5'>
											Design systems
										</h6>

										{/* Heading */}
										<h2 className='display-3 mb-5 mb-md-8'>
											<q>
												The NOLT working capital loan helped me expand my
												business with ease{' '}
												<span className='text-underline-warning'></span>{' '}
											</q>
										</h2>

										{/* Footer */}
										<footer>
											{/* Avatar */}
											<div className='avatar mb-4'>
												<img
													className='avatar-img rounded-circle'
													src='assets/img/EmekaOgu.png'
													alt='...'
												/>
											</div>

											{/* Heading */}
											<h4 className='font-sans-serif mb-0'>Emeka Ogu</h4>

											{/* Position */}
											<p className='fs-sm text-muted mt-n1 mb-0'>
												Loan Investor
											</p>
										</footer>
									</blockquote>
								</div>
								<div className='col-12 px-4'>
									<blockquote className='text-center mb-0'>
										{/* Preheading */}
										<h6 className='text-uppercase text-warning mb-3 mb-md-5'>
											Design systems
										</h6>

										{/* Heading */}
										<h2 className='display-3 mb-5 mb-md-8'>
											<q>
												NOLT salary advance product is A fast and convenient way
												to pay bills.{' '}
												<span className='text-underline-warning'></span>{' '}
											</q>
										</h2>

										{/* Footer */}
										<footer>
											{/* Avatar */}
											<div className='avatar mb-4'>
												<img
													className='avatar-img rounded-circle'
													src='assets/img/NonyeIgbokwe.png'
													alt='...'
												/>
											</div>

											{/* Heading */}
											<h4 className='font-sans-serif mb-0'>Nonye Igbokwe</h4>

											{/* Position */}
											<p className='fs-sm text-muted mt-n1 mb-0'>
												Loan Borrower
											</p>
										</footer>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* CTA */}
			<section className='position-relative py-2 py-md-2 bg-white'>
				{/* Decoration */}

				{/* Content */}
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-8 text-center'>
							{/* Preheading */}
							<h6 className='text-uppercase text-primary mb-5'>
								Management fund
							</h6>

							{/* Heading */}
							<h2 className='display-1 mb-4'>Give us a try.</h2>

							{/* Text */}
							<p>
								When you place your hard earned funds with NOLT we deliver
								attractive long/short term returns through an active investment
								process that considers risk, diversification and good yields to
								produce a balanced result.
							</p>

							{/* Button */}
							<Link className='btn btn-primary lift' to='/contact-us'>
								Contact Us Today
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
