import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Calculator } from './components/Calculator'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { About } from './pages/About'
import { Borrow } from './pages/Borrow'
import { Contact } from './pages/Contact'
import { FAQ } from './pages/FAQ'
import { Home } from './pages/Home'
import { Invest } from './pages/Invest'

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path='/calculator' component={Calculator} />
				<Route path='/' component={AnotherRoute} />
			</Switch>
		</BrowserRouter>
	)
}
const AnotherRoute = () => (
	<>
		<Header />
		<Switch>
			<Route path='/faq' component={FAQ} />
			<Route path='/contact-us' component={Contact} />
			<Route path='/about-us' component={About} />
			<Route path='/invest' component={Invest} />
			<Route path='/borrow' component={Borrow} />
			<Route path='/' component={Home} />
		</Switch>
		<Footer />
	</>
)

export default App
