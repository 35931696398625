import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
	return (
		<>
			{/* FOOTER */}
			<footer className='footer py-8 pt-md-11 bg-dark border-multicolor'>
				<div className='container-lg'>
					<div className='row'>
						<div className='col-md-4'>
							{/* Brand */}
							<h2 className='font-serif text-white mb-1'>NOLT</h2>

							{/* Text */}
							<p className='text-white-60'>Creating your own wealth should be no long thing.
							</p>
						</div>
						<div className='col-6 col-md'>
							{/* Heading */}
							<h6 className='text-uppercase text-white mb-3 mb-md-5'>
								Resources
							</h6>

							{/* List */}
							<ul className='list-unstyled'>
								<li className='mb-3'>
									<Link className='text-white-60' to='/contact-us'>
										Blog
									</Link>
								</li>
								<li className='mb-3'>
									<Link className='text-white-60' to='/about-us'>
										Terms and Conditions
									</Link>
								</li>
								<li className='mb-3'>
									<Link className='text-white-60' to='/faq'>
										Download Gallery
									</Link>
								</li>
								<li>
									<Link className='text-white-60' to='/faq'>
										Privacy Policy
									</Link>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md'>
							{/* Heading */}
							<h6 className='text-uppercase text-white mb-3 mb-md-5'>Company</h6>

							{/* List */}
							<ul className='list-unstyled'>
								<li className='mb-3'>
									<a className='text-white-60' href='/invest#loans'>
									About Us 
									</a>
								</li>
								<li className='mb-3'>
									<a className='text-white-60' href='/invest#products'>
									Responsible Lending
									</a>
								</li>
								<li className='mb-3'>
									<a className='text-white-60' href='/invest#lending'>
									Careers
									</a>
								</li>
								<li className='mb-3'>
									<a className='text-white-60' href='/invest#lending'>
									Contact
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}
