import React from 'react'

export const About = () => {
	return (
		<>
			{/* WELCOME */}
			<section className='pt-6 pt-md-11'>
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-10 text-center'>
							{/* Preheading */}
							<h6 className='text-uppercase text-primary mb-5'>Our Mission</h6>

							{/* Heading */}
							<h2 className='display-3 mb-10'>
								“Our mission is to create wealth for the underserved.”{' '}
								<span className='text-underline text-underline-warning'>
 								</span>
								
							</h2>
						</div>
					</div>
					<div className='row'>
						<div className='col-6 col-md text-center border-end mb-6 mb-md-0'>
							{/* Heading */}
							<h2 className='display-4'>7</h2>

							{/* Text */}
							<p className='mb-0 text-muted'>Loan Products</p>
						</div>
						<div className='col-6 col-md text-center border-end-md mb-6 mb-md-0'>
							{/* Heading */}
							<h2 className='display-4'>2,000+</h2>

							{/* Text */}
							<p className='mb-0 text-muted'>Customers</p>
						</div>
						<div className='col-6 col-md text-center border-end'>
							{/* Heading */}
							<h2 className='display-4'>N2Billion</h2>

							{/* Text */}
							<p className='mb-0 text-muted'>Loans Disbursed</p>
						</div>
					 
					</div>
				</div>
			</section>

			{/* SLIDER */}
			<section
				className='position-relative pt-10 pt-md-12'
				style={{ zIndex: 1 }}
			>
				<div className='container-lg'>
					<div className='row'>
						<div className='col-12'>
							{/* Decoration */}
							<div className='position-absolute bottom-end text-warning mb-n8 me-n7'>
							
						
							
								 
							</div>

							<div className='w-100'>
								{/* Image */}
								<img
									className='img-fluid'
									src='assets/img/AboutUs.jpg'
									alt='...'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* SHAPE */}
			<div className='position-relative'>
				<div className='shape shape-fluid-x shape-top text-dark pb-10 pb-md-16 pb-lg-18'>
					<div className='shape-img pb-8 pb-md-10'>
						<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
							<path d='M0 25h25L75 0h25v50H0z' fill='currentColor' />
						</svg>{' '}
					</div>
				</div>
			</div>
			{/* <section className='bg-dark'></section> */}

			{/* SHAPE */}
			<div className='position-relative'>
				<div className='shape shape-fluid-x shape-bottom text-dark'>
					<div className='shape-img pb-8 pb-md-12'>
						<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
							<path d='M0 0h100v50H75L25 25H0z' fill='currentColor' />
						</svg>{' '}
					</div>
				</div>
			</div>

		
			{/* MAP */}
			<section className='bg-light'>
				<div className='container-lg'>
					<div className='row align-items-center justify-content-between'>
						
							<div className='col-12 px-4'>
									{/* Blockquote */}
									<blockquote>
										{/* Logo */}
									 <br/><br/> <br/><br/>
									 	 
													<h6 className='text-center text-uppercase text-primary mb-5'>Our Vision</h6>


										{/* Text */}
										<p className='h1 text-center mb-8'>
										“Our vision is to be the future <br/>of financial services in the world.”
										</p>

										{/* Footer */}
									 
									</blockquote>
								</div>
						<div className='order-md-0 py-11 py-md-13 px-20' style={{ marginTop: '-100px' }}>

				{/* Content */}
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-10 text-center'>

							{/* Heading */}
							<h2 className='display-1 mb-4'>
							No long thing when it comes to your finances.
								</h2>

							{/* Text */}
							<p>
					We hear many stories of people trading in their big ideas for small ones because they are not able to access the funding they need.
For that next big idea, fully furnished home, new car to get you off your feet, or simply saving up for the future, NOLT will help you prepare for that financial milestone.
In Nigeria, NOLT is giving social impact banking a whole new lease on life. We believe that ease of banking should be no long thing and as such we have embarked on a mission to fulfill this mantra.
NOLT uses a proprietary online platform that incorporates machine learning, data science, and artificial intelligence in a secure environment to address Nigeria’s lending void for both entrepreneurs and SMEs.
We want to bring ease and versatility into the business lending landscape, just like any good business, and we are committed to helping small businesses expand.
							</p>

						</div>
					</div>
				</div>
						 
						</div>
					</div>
				</div>
			</section>

			{/* PRESS */}
			<section className='pb-10 pb-md-12 bg-light'>
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-8 text-center'>
							{/* Heading */}
							<h2 className='display-3 mb-4'>Explore</h2>

							{/* Text */}
						 <br/>
						</div>
					</div>
					<div className='row mt-n6'>
						<div className='col-md-4'>
							{/* Card */}
							<div className='card card-sm rounded-top-start rounded-bottom-end lift lift-lg mt-6'>
								{/* Image */}
								<img
									className='card-img-top rounded-top-start'
									src='assets/img/blog/post-header-1.jpg'
									alt='...'
								/>

								{/* Shape */}
								<div className='position-relative'>
									<div className='shape shape-fluid-x shape-top text-white'>
										<div className='shape-img pb-5'>
											<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
												<path d='M0 25h25L75 0h25v50H0z' fill='currentColor' />
											</svg>{' '}
										</div>
									</div>
								</div>

								{/* Body */}
								<div className='card-body'>
									{/* Heading */}
									<h2 className='mb-2'>
										Loan Services
									</h2>

									{/* Text */}
									<p className='fs-sm mb-4'>
										Loan Services such as the salary advance loans helps you to
access a portion of your salary before payday with ease.
									</p>

									{/* Footer */}
								 

									{/* Link */}
									{/* <a className='stretched-link' href='blog-post.html'></a> */}
								</div>
							</div>
						</div>
						<div className='col-md-4'>
							{/* Card */}
							<div className='card card-sm rounded-top-start rounded-bottom-end lift lift-lg mt-6'>
								{/* Image */}
								<img
									className='card-img-top rounded-top-start'
									src='assets/img/blog/post-header-2.jpg'
									alt='...'
								/>

								{/* Shape */}
								<div className='position-relative'>
									<div className='shape shape-fluid-x shape-top text-white'>
										<div className='shape-img pb-5'>
											<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
												<path d='M0 25h25L75 0h25v50H0z' fill='currentColor' />
											</svg>{' '}
										</div>
									</div>
								</div>

								{/* Body */}
								<div className='card-body'>
									{/* Heading */}
									<h2 className='mb-2'>Fund Management.</h2>

									{/* Text */}
									<p className='fs-sm mb-4'>
										NOLT delivers
attractive long/short term returns through an active investment
process that considers risk.
									</p>

									{/* Footer */}
								 

									{/* Link */}
									{/* <a className='stretched-link' href='blog-post.html'></a> */}
								</div>
							</div>
						</div>
						<div className='col-md-4'>
							{/* Card */}
							<div className='card card-sm rounded-top-start rounded-bottom-end lift lift-lg mt-6'>
								{/* Image */}
								<img
									className='card-img-top rounded-top-start'
									src='assets/img/blog/post-header-3.jpg'
									alt='...'
								/>

								{/* Shape */}
								<div className='position-relative'>
									<div className='shape shape-fluid-x shape-top text-white'>
										<div className='shape-img pb-5'>
											<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
												<path d='M0 25h25L75 0h25v50H0z' fill='currentColor' />
											</svg>{' '}
										</div>
									</div>
								</div>

								{/* Body */}
								<div className='card-body'>
									{/* Heading */}
									<h2 className='mb-2'>
										Financial Advisory
									</h2>

									{/* Text */}
									<p className='fs-sm mb-4'>
										We
have an experienced in house Investment team spearheaded
by an Investment Specialist with years of
experience.
									</p>

									{/* Footer */}
								 

									{/* Link */}
									{/* <a className='stretched-link' href='blog-post.html'></a> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
