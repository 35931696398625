import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Calculator } from './Calculator'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<a
		href='/'
		className='nav-link dropdown-toggle'
		aria-haspopup='true'
		aria-expanded='false'
		role='menuitem'
		ref={ref}
		onClick={(e) => {
			e.preventDefault()
			onClick(e)
		}}
	>
		{children}
	</a>
))
export const Header = () => {
	return (
		<>
			{/* NAVBAR */}
			<nav className='navbar navbar-expand-lg navbar-light bg-light'>
				<div className='container-lg'>
					{/* Brand */}
					<Link
						className='navbar-brand d-lg-none'
						to='/'
						style={{
							marginLeft: '275px',
							marginTop: '-50px',
							marginBottom: '-50px',
						}}
					>
						<img src='assets/img/NOLTlogo.png' alt='logo' width='150px' />
					</Link>

					{/* Toggler */}
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>

					{/* Collapse */}
					<div className='collapse navbar-collapse' id='navbarSupportedContent'>
						{/* Navigation */}
						<ul className='navbar-nav justify-content-end w-100'></ul>

						{/* Brand */}
						<Link
							className='navbar-brand d-none d-lg-block px-lg-6'
							to='/'
							style={{
								marginLeft: '0px',
								marginTop: '-50px',
								marginBottom: '-50px',
							}}
						>
							{/* <img src="/assets/" alt="logo"/> */}
							<img src='assets/img/NOLTlogo.png' alt='logo' width='150px' />
						</Link>

						{/* Navigation */}
						<ul className='navbar-nav justify-content-start w-100'>
							{/* <li className='nav-item'>
								<Dropdown>
									<Dropdown.Toggle as={CustomToggle}>Borrow</Dropdown.Toggle>

									<Dropdown.Menu>
										<a href='/invest#loans' className='dropdown-item'>
											Salary Earners Loan
										</a>
										<a href='/invest#loans' className='dropdown-item'>
											Annuitant Loan
										</a>
										<a href='/invest#loans' className='dropdown-item'>
											Invoice Financing
										</a>
										<a href='/invest#loans' className='dropdown-item'>
											Working Capital Loan
										</a>
										<a href='/invest#loans' className='dropdown-item'>
											Asset Finance
										</a>
										<a href='/invest#loans' className='dropdown-item'>
											Automobile loans
										</a>
										<a href='/invest#loans' className='dropdown-item'>
											Travel Loans
										</a>
									</Dropdown.Menu>
								</Dropdown>
							</li> */}
							<li className='nav-link dropdown-toggle'>
								<a href='/borrow' className='dropdown-item'>
									Borrow
								</a>
							</li>
							<li className='nav-link dropdown-toggle'>
								<a href='/invest' className='dropdown-item'>
									Invest
								</a>
							</li>
							<li className='nav-item'>
								<Link
									className='nav-link dropdown-toggle'
									data-bs-toggle='modal'
									data-bs-target='#calculatorModal'
									to='#'
								>
									Loan Calculator
								</Link>
							</li>
							<li className='nav-item'>
								<Dropdown>
									<Dropdown.Toggle as={CustomToggle}>About</Dropdown.Toggle>

									<Dropdown.Menu>
										<a href='/about-us' className='dropdown-item'>
											Our People
										</a>
										<a href='/faq' className='dropdown-item'>
											FAQs
										</a>
										<a href='/contact-us' className='dropdown-item'>
											Contact Us
										</a>
									</Dropdown.Menu>
								</Dropdown>
							</li>
							<li className='nav-item'>
								<a
									href='https://app.noltfinance.com/login'
									className='btn btn-dark d-none d-lg-block'
									style={{ marginRight: '150px', marginLeft: '250px' }}
								>
									Sign In
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			{/* Modal */}
			<div
				className='modal fade'
				id='calculatorModal'
				tabIndex='-1'
				aria-labelledby='calculatorModalLabel'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-dialog-centered modal-xl'>
					<div className='modal-content'>
						<div className='modal-body'>
							<Calculator />
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn btn-secondary'
								data-bs-dismiss='modal'
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
