import React, { useState } from 'react'
export const Borrow = () => {
	const [selected, setSelected] = useState({})
	const data = [
		{
			img: '/assets/img/illustrations/illustration-5.png',
			title: 'Salary Advance',
			summary:
				'Nolt Salary Advance allows you borrow against your future earnings to access capital now. You will be allowed to take a loan using at most 33% of your monthly income as basis for your monthly repayment.',
			fullData: `<b>Get quick access to capital using collateral you already have</b>

        <p>Loan Amount: Max 5,000,000<br />Loan Tenure: 1-6 months<br />
        Processing Time:<br />Interest Rate: 4% per month (reducing balance)</p>
        
        <b>Let your salary do even more for you</b>
        <p>Sometimes that monthly pay check just isn’t enough to cover all your expenses. Unexpected costs come up and you have to figure out how to cover them. If you have been an employee in a reputable organization for at least 6 months, this loan may be just for you.</p>
        
        <p>Nolt Salary Advance allows you borrow against your future earnings to access capital now. You will be allowed to take a loan using at most 33% of your monthly income as basis for your monthly repayment. </p>
        
        You will need to provide the following documents in order to qualify for the loan
        <ul>
            <li>Valid National ID card (NIMC, Voters card, etc)</li>
            <li>Work ID Card</li>
            <li>Utility Bill (PHCN, LAWMA, etc)</li>
            <li>Payment Slip or Statement of Account</li>
        </ul>
        
        <b>Pre- Qualification</b><br />
        HR must forward a letter of introduction including schedule of staff detailing the following: 
        <ul>
            <li>Employee's name</li>
            <li>Employee's Job Role</li>
            <li>Employee's Salary</li>
            <li>Ongoing Staff Loan with the Employer</li>
            <li>Amount requested</li>
            <li>HR must agree to deduct from source and remit same to NOLT</li>
        </ul>
        NOTE: HR is not liable in the event of default on the part of the staff/debtor <br />
        Requirements: 
            <ul>
                <li>3 or 6 months salary statement of account depending on the tenor</li>
                <li>Utility bill not less than 3months</li>
                <li>Valid government issued means of ID card</li>
                <li>Recent passport photograph</li>
                <li>Work ID, employment letter and confirmation letter</li>
                <li>One guarantor with a personal valid cheque</li>
            </ul>
        `,
		},
		{
			img: '/assets/img/illustrations/illustration-4.png',
			title: 'Annuitant Loan',
			summary:
				'Our Annuitant loan is for people who have retired from active service in any private organization, but require extra funds to meet financial needs.',
			fullData: `<p>Loan Amount: Max 5,000,000<br />Loan Tenure : 6-12 months<br />
        Processing Time:<br />Interest Rate: 4% per month</p>
        
        <b>Being retired should not stop your access to extra funds</b>
        <p>As a retiree, you most likely want to sit back, relax and enjoy the benefits you have accrued with your PFA. This sounds like the dream, but sometimes life has other plans and NOLT wants to ensure you have access to extra funds you may need, to keep you relaxed.
        </p><p>
        Our Annuitant loan is for people who have retired from active service in any private organization, but require extra funds to meet financial needs. This loan will provide access to a maximum of 50% of your monthly income being remitted by your Pension Fund Administrator (PFA).</p>`,
		},
		{
			img: '/assets/img/illustrations/illustration-9.png',
			title: 'Invoice Financing',
			summary:
				'Covering your short-term operational needs with this may be the key to expanding your business as you wish.',
			fullData: `<b>Meet deadlines quickly</b>

            <p>Loan Amount:  N100,000 – 5,000,000<br />Loan Tenure : 1 – 3 months<br />
            Processing Time:<br />Interest Rate: 5%</p>
            
            <b>Act quick even when you are cash strapped</b>
            
            <p>No matter how much planning you do, sometimes having a business is just full of many surprises. Coming up with quick solutions can often be the difference between delivering services and losing customers.</p>
            
            <p>This product is primarily for people in the business of supplying goods and services. It allows them to meet deadlines for supply when they are low on cash. The amount of loan given is determined by the Company invoice provided</p>`,
		},
		{
			img: '/assets/img/illustrations/illustration-8.png',
			title: 'Working Capital Loan',
			summary: 'Covering your short-term financial needs with ease',
			fullData: `<b>Covering your short-term financial needs with ease</b>

            <p>Loan Amount: Max 20,000,000<br />Loan Tenure : 6 – 12 months<br />
            Processing Time:<br />Interest Rate: 5%</p>
            
            <b>Boost your working capital to meet your goals</b>
            <p>Do you own a fashion boutique, shopping mall, restaurant or any business that deals with inventory and stocks? Welcome, this is the loan you are looking for. Covering your short-term operational needs with this may be the key to expanding your business as you wish.
            </p><p>
            To acquire this loan, you will either need to provide a guarantor with postdate cheque depending on the amount borrowed or a moveable collateral to secure the loan (e.g a car registered in your name).</p>`,
		},
		{
			img: '/assets/img/illustrations/illustration-1.png',
			title: 'Asset Financing',
			summary:
				'Plants & machinery, electronic gadgets – Consider it all covered',
			fullData: `<b>Plants & machinery, electronic gadgets – Consider it all covered</b>

            <p>Loan Amount: N20,000,000 max<br />Loan Tenure: 6-18 months<br />
            Processing Time<br />Interest Rate: 5%</p>
            
            <b>Your equipment, but financeable</b>
            
            <p>Need to purchase a high-speed machine? A new tractor? Or new desktops to keep your employees working at the highest level of productivity? This loan is for that. For every business equipment you can think of, consider your wish list covered. 
            </p><p>
            Use this loan to purchase any kind of equipment you or your business requires. This includes plant and machinery, moveable assets and any other electronic gadget for business or personal use. Your proforma invoice from a reputable vendor will determine the total amount you can borrow. The cost of your assets will be covered by us till you complete payment and ownership will be transferred.
            </p>`,
		},
		{
			img: '/assets/img/illustrations/illustration-3.png',
			title: 'Automobile Loans',
			summary: 'Finance a used personal vehicle with an auto loan',
			fullData: `<b>Finance a used personal vehicle with an auto loan</b>

            <p>Loan Amount: Max 5,000,000<br />Loan Tenure : Up to 12 months<br />
            Processing Time:<br />Interest Rate: 5%</p>
            
            
            <b>Your dream car is a loan away</b>
            <p>Tired of spending money on taxi services, public transportation or you’re just looking for that extra comfort driving a car to your destination provides? We don’t believe you need to cash in all your savings to afford the luxury of owning a car. Our automobile loans can get you where you need to go.
            </p><p>
            The NOLT Auto Finance Scheme provides loans to people looking to purchase their own cars.</p>
            <b>Eligibility Criteria</b>
            <ul>
                <li>Only salaried customers and business owners can apply </li>
                <li>Vehicles open to finance are: Saloon cars, SUVs, Pick-up Vans and Buses </li>
                <li>Vehicles with maximum mass above 2 tonnes are excluded </li>
                <li>Loan repayment does not exceed 33.33% of obligor’s net monthly income </li>
                <li>At least one month of payroll administration </li>
                <li>Completed Retail Lending Application Form (RLAF)</li>
            </ul>

            <b>Features</b>
            <ul>
                <li>Minimum Equity Contribution: 30% for businesses and 10% for employees of Blue-Chip Companies (Select Corporates).</li>
                <li>Tracking is mandatory on vehicles financed by the Nolt Investment Ltd (currently ₦70,000) during the repayment period.</li> 
                <li>All vehicles financed are comprehensively insured: 2.5% for Saloon Cars and SUVs; 3.0% for Light Pick-up Vans and Buses.</li>
                <li>Cost of vehicle registration is to be borne by the customer. However, the registration should be done in the name of “Nolt Investment LTD / Customer” </li>
                <li>Only second hand vehicles can be financed (a maximum of 1 car per customer can be financed at a time) </li>
                <li>To ensure that we guarantee minimum service standards for our customers, only purchases from Nolt Investment Ltd approved retailers can be financed under this product. </li>
                <li>The Pro-forma invoice of the vehicle is required to still be valid at the point of loan disbursement. </li>
                <li>Confirmation of current price and availability must be obtained from the auto vendor before proceeding with disbursement.</li>
                <li>Completed Retail Lending Application Form (RLAF). </li>
                <li>Pro-forma Invoice in the name of “Nolt Investment Ltd. / Customer”. </li>
                <li>Valid means of identification. </li>
                <li>Letter of Introduction (for salaried customers). </li>
                <li>Domiciliation of salary account with a licensed commercial bank and/or MFB (for salaried customers)</li>
                <li>Employer’s Confirmation and Undertaking Form (for salaried customers) </li>
                <li>Recent Payslips / Salary Bank Statement (for salaried customers) </li>
            </ul>
            `,
		},
		{
			img: '/assets/img/illustrations/illustration-2.png',
			title: 'Travel Loans',
			summary:
				'Our travel loan gives you extra money to be used for funding travel expenses/activities. It can help cover all the costs associated with your holiday.',
			fullData: `<p>Loan Amount: Max 5,000,000<br />Loan Tenure : <br />
            Processing Time:<br />Interest Rate: </p>
            
            
            
            <b>Think of a personal loan.. but for travelling</b>
            
            <p>Are you dreaming of going on a holiday, but don’t quite have all the funds to make it a reality? Do you see yourself daydreaming about all the places you could go, lying on a distant beach, listening to the waves? Well your holiday dreams do not have to be as distant as they may seem. With a NOLT travel loan, you could very well be stretched out on that beach.</p>
            
            <p>Our travel loan gives you extra money to be used for funding travel expenses/activities. It can help cover all the costs associated with your holiday, including airfares, accommodation, tours and even travel accessories.</p>`,
		},
	]
	return (
		<>
			{/* STATS */}
			<section className='py-10 py-md-12' id='application'>
				<div className='container-lg position-relative' style={{ zIndex: 1 }}>
					<div className='row align-items-center justify-content-between'>
						<div className='col-md-5 col-xl-6 order-md-1'>
							{/* Image grid */}
							<div className='img-grid img-grid-2 mb-8 mb-md-0'>
								<div className='img-grid-item'>
									{/* Image */}
									<img
										className='img-fluid border border-4 border-md-7 border-white shadow'
										src='assets/img/coworking/office-1.jpg'
										alt='...'
										data-jarallax-element='-75'
									/>
								</div>
								<div className='img-grid-item'>
									{/* Image */}
									<img
										className='img-fluid border border-4 border-md-7 border-white shadow'
										src='assets/img/coworking/office-2.jpg'
										alt='...'
										data-jarallax-element='-45'
									/>
								</div>
								<div className='img-grid-item'>
									{/* Image */}
									<img
										className='img-fluid border border-4 border-md-7 border-white shadow'
										src='assets/img/coworking/office-3.jpg'
										alt='...'
										data-jarallax-element='-25'
									/>
								</div>
							</div>
						</div>
						<div
							className='col-md-7 col-lg-6 col-xl-5 order-md-0'
							data-aos='fade-up'
						>
							{/* Divider */}
							<hr className='hr-sm bg-warning ms-0 mb-6' />

							{/* Heading */}
							<h2 className='display-4 mb-9'>
								Steps for Securing a Loan from NOLT Finance.
							</h2>

							{/* Heading */}
							<h6 className='text-uppercase text-warning'>
								― Application Form
							</h6>

							{/* Body */}
							<div className='row mb-7'>
								<div className='col-4'>
									{/* Value */}
									<h3 className='display-3 mb-0'>
										<span
											data-countup='{"startVal": 0}'
											data-to='1'
											data-aos
											data-aos-id='countup:in'
										></span>
									</h3>
								</div>
								<div className='col align-self-center border-start'>
									{/* Text */}
									<p className='mb-0'>
										Complete Loan Application form on our website.
									</p>
								</div>
							</div>

							{/* Heading */}
							<h6 className='text-uppercase text-warning'>― Contact</h6>

							{/* Body */}
							<div className='row mb-7'>
								<div className='col-4'>
									{/* Value */}
									<h3 className='display-3 mb-0'>
										<span
											data-countup='{"startVal": 0}'
											data-to='2'
											data-aos
											data-aos-id='countup:in'
										></span>
									</h3>
								</div>
								<div className='col align-self-center border-start'>
									{/* Text */}
									<p className='mb-0'>
										You will be contacted by a member of our team to further
										instruct you on the fine details
									</p>
								</div>
							</div>

							{/* Heading */}
							<h6 className='text-uppercase text-warning'>― Receive</h6>

							{/* Body */}
							<div className='row mb-7'>
								<div className='col-4'>
									{/* Value */}
									<h3 className='display-3 mb-0'>
										<span
											data-countup='{"startVal": 0}'
											data-to='3'
											data-aos
											data-aos-id='countup:in'
										></span>
									</h3>
								</div>
								<div className='col align-self-center border-start'>
									{/* Text */}
									<p className='mb-0'>
										Once your loan application is approved.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* SHAPE */}
			<div className='position-relative'>
				<div className='shape shape-fluid-x shape-top text-light'>
					<div className='shape-img pb-9 pb-md-15'>
						<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
							<path d='M0 25h25L75 0h25v50H0z' fill='currentColor' />
						</svg>{' '}
					</div>
				</div>
			</div>

			{/* TASKS */}
			<section className='bg-dark'>
				<div className='container-lg'>
					<div className='row'>
						<div className='col-12'>
							{/* Divider */}
							<hr className='hr-sm bg-warning mx-auto mb-10 mb-md-12' />

							{/* Stack */}
							<div className='card-stack'>
								{/* Card */}
								{data.map((d, i) => (
									<div key={i} className='card-stack-item mb-9'>
										<div className='card card-lg'>
											<div className='row gx-0'>
												<div className='col-md order-md-1 d-flex align-items-center bg-primary'>
													{/* Image */}
													<img className='card-img-top' src={d.img} alt='...' />
												</div>
												<div className='col-md-5 order-md-0 d-flex align-items-center bg-white'>
													<div className='card-body text-center'>
														{/* Icon */}
														<div className='icon text-primary-light mx-auto mb-4'>
															<svg
																width='24'
																height='24'
																viewBox='0 0 24 24'
																xmlns='http://www.w3.org/2000/svg'
															>
																<g fill='none' fillRule='evenodd'>
																	<path d='M0 0h24v24H0z' />
																	<path
																		d='M8 3v.5A1.5 1.5 0 009.5 5h5A1.5 1.5 0 0016 3.5V3h2a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V5a2 2 0 012-2h2z'
																		fill='#335EEA'
																		opacity='.3'
																	/>
																	<path
																		d='M10.875 15.75a.946.946 0 01-.67-.287l-1.918-1.917a.926.926 0 010-1.342c.384-.383 1.007-.383 1.342 0l1.246 1.246 3.162-3.162a.926.926 0 011.342 0 .926.926 0 010 1.341l-3.833 3.834a.946.946 0 01-.671.287zM11 2a1 1 0 012 0h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H11z'
																		fill='#335EEA'
																	/>
																</g>
															</svg>{' '}
														</div>

														{/* Heading */}
														<h1 className='font-sans-serif mb-4 mt-auto'>
															{d.title}
														</h1>

														{/* Text */}
														<p>{d.summary}</p>

														{/* Link */}
														<a
															className='h6 text-uppercase text-decoration-none mt-auto'
															href='#!'
															data-bs-toggle='modal'
															data-bs-target='#borrowModal'
															onClick={() => setSelected(d)}
														>
															Learn more
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* TESTIMONIALS */}
			

			{/* SHAPE */}
			<div className='position-relative'>
				{/* Decoration */}
				<div className='position-absolute bottom-start text-warning mb-n10 ms-n8 d-none d-md-block'>
					<svg
						width='185'
						height='186'
						viewBox='0 0 185 186'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<circle cx='2' cy='2' r='2' fill='currentColor' />
						<circle cx='22' cy='2' r='2' fill='currentColor' />
						<circle cx='42' cy='2' r='2' fill='currentColor' />
						<circle cx='62' cy='2' r='2' fill='currentColor' />
						<circle cx='82' cy='2' r='2' fill='currentColor' />
						<circle cx='102' cy='2' r='2' fill='currentColor' />
						<circle cx='122' cy='2' r='2' fill='currentColor' />
						<circle cx='142' cy='2' r='2' fill='currentColor' />
						<circle cx='162' cy='2' r='2' fill='currentColor' />
						<circle cx='182' cy='2' r='2' fill='currentColor' />
						<circle cx='2' cy='22' r='2' fill='currentColor' />
						<circle cx='22' cy='22' r='2' fill='currentColor' />
						<circle cx='42' cy='22' r='2' fill='currentColor' />
						<circle cx='62' cy='22' r='2' fill='currentColor' />
						<circle cx='82' cy='22' r='2' fill='currentColor' />
						<circle cx='102' cy='22' r='2' fill='currentColor' />
						<circle cx='122' cy='22' r='2' fill='currentColor' />
						<circle cx='142' cy='22' r='2' fill='currentColor' />
						<circle cx='162' cy='22' r='2' fill='currentColor' />
						<circle cx='182' cy='22' r='2' fill='currentColor' />
						<circle cx='2' cy='42' r='2' fill='currentColor' />
						<circle cx='22' cy='42' r='2' fill='currentColor' />
						<circle cx='42' cy='42' r='2' fill='currentColor' />
						<circle cx='62' cy='42' r='2' fill='currentColor' />
						<circle cx='82' cy='42' r='2' fill='currentColor' />
						<circle cx='102' cy='42' r='2' fill='currentColor' />
						<circle cx='122' cy='42' r='2' fill='currentColor' />
						<circle cx='142' cy='42' r='2' fill='currentColor' />
						<circle cx='162' cy='42' r='2' fill='currentColor' />
						<circle cx='182' cy='42' r='2' fill='currentColor' />
						<circle cx='2' cy='62' r='2' fill='currentColor' />
						<circle cx='22' cy='62' r='2' fill='currentColor' />
						<circle cx='42' cy='62' r='2' fill='currentColor' />
						<circle cx='62' cy='62' r='2' fill='currentColor' />
						<circle cx='82' cy='62' r='2' fill='currentColor' />
						<circle cx='102' cy='62' r='2' fill='currentColor' />
						<circle cx='122' cy='62' r='2' fill='currentColor' />
						<circle cx='142' cy='62' r='2' fill='currentColor' />
						<circle cx='162' cy='62' r='2' fill='currentColor' />
						<circle cx='182' cy='62' r='2' fill='currentColor' />
						<circle cx='2' cy='82' r='2' fill='currentColor' />
						<circle cx='22' cy='82' r='2' fill='currentColor' />
						<circle cx='42' cy='82' r='2' fill='currentColor' />
						<circle cx='62' cy='82' r='2' fill='currentColor' />
						<circle cx='82' cy='82' r='2' fill='currentColor' />
						<circle cx='102' cy='82' r='2' fill='currentColor' />
						<circle cx='122' cy='82' r='2' fill='currentColor' />
						<circle cx='142' cy='82' r='2' fill='currentColor' />
						<circle cx='162' cy='82' r='2' fill='currentColor' />
						<circle cx='182' cy='82' r='2' fill='currentColor' />
						<circle cx='2' cy='102' r='2' fill='currentColor' />
						<circle cx='22' cy='102' r='2' fill='currentColor' />
						<circle cx='42' cy='102' r='2' fill='currentColor' />
						<circle cx='62' cy='102' r='2' fill='currentColor' />
						<circle cx='82' cy='102' r='2' fill='currentColor' />
						<circle cx='102' cy='102' r='2' fill='currentColor' />
						<circle cx='122' cy='102' r='2' fill='currentColor' />
						<circle cx='142' cy='102' r='2' fill='currentColor' />
						<circle cx='162' cy='102' r='2' fill='currentColor' />
						<circle cx='182' cy='102' r='2' fill='currentColor' />
						<circle cx='2' cy='122' r='2' fill='currentColor' />
						<circle cx='22' cy='122' r='2' fill='currentColor' />
						<circle cx='42' cy='122' r='2' fill='currentColor' />
						<circle cx='62' cy='122' r='2' fill='currentColor' />
						<circle cx='82' cy='122' r='2' fill='currentColor' />
						<circle cx='102' cy='122' r='2' fill='currentColor' />
						<circle cx='122' cy='122' r='2' fill='currentColor' />
						<circle cx='142' cy='122' r='2' fill='currentColor' />
						<circle cx='162' cy='122' r='2' fill='currentColor' />
						<circle cx='182' cy='122' r='2' fill='currentColor' />
						<circle cx='2' cy='142' r='2' fill='currentColor' />
						<circle cx='22' cy='142' r='2' fill='currentColor' />
						<circle cx='42' cy='142' r='2' fill='currentColor' />
						<circle cx='62' cy='142' r='2' fill='currentColor' />
						<circle cx='82' cy='142' r='2' fill='currentColor' />
						<circle cx='102' cy='142' r='2' fill='currentColor' />
						<circle cx='122' cy='142' r='2' fill='currentColor' />
						<circle cx='142' cy='142' r='2' fill='currentColor' />
						<circle cx='162' cy='142' r='2' fill='currentColor' />
						<circle cx='182' cy='142' r='2' fill='currentColor' />
						<circle cx='2' cy='162' r='2' fill='currentColor' />
						<circle cx='22' cy='162' r='2' fill='currentColor' />
						<circle cx='42' cy='162' r='2' fill='currentColor' />
						<circle cx='62' cy='162' r='2' fill='currentColor' />
						<circle cx='82' cy='162' r='2' fill='currentColor' />
						<circle cx='102' cy='162' r='2' fill='currentColor' />
						<circle cx='122' cy='162' r='2' fill='currentColor' />
						<circle cx='142' cy='162' r='2' fill='currentColor' />
						<circle cx='162' cy='162' r='2' fill='currentColor' />
						<circle cx='182' cy='162' r='2' fill='currentColor' />
						<circle cx='2' cy='182' r='2' fill='currentColor' />
						<circle cx='22' cy='182' r='2' fill='currentColor' />
						<circle cx='42' cy='182' r='2' fill='currentColor' />
						<circle cx='62' cy='182' r='2' fill='currentColor' />
						<circle cx='82' cy='182' r='2' fill='currentColor' />
						<circle cx='102' cy='182' r='2' fill='currentColor' />
						<circle cx='122' cy='182' r='2' fill='currentColor' />
						<circle cx='142' cy='182' r='2' fill='currentColor' />
						<circle cx='162' cy='182' r='2' fill='currentColor' />
						<circle cx='182' cy='182' r='2' fill='currentColor' />
					</svg>{' '}
				</div>

				{/* Shape */}
				<div className='shape shape-fluid-x shape-bottom text-dark'>
					<div className='shape-img pb-9 pb-md-15'>
						<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
							<path d='M0 0h100v50H75L25 25H0z' fill='currentColor' />
						</svg>{' '}
					</div>
				</div>
			</div>

			{/* TOOLS */}
			<section className='position-relative pt-12 pt-md-15'></section>

			{/* Modal */}
			<div
				className='modal fade'
				id='borrowModal'
				tabIndex='-1'
				aria-labelledby='borrowModalLabel'
				aria-hidden='true'
			>
				<div className='modal-dialog modal-dialog-centered modal-xl'>
					<div className='modal-content'>
						<div className='modal-body'>
							<p className='h2'>{selected.title}</p>
							<div
								dangerouslySetInnerHTML={{ __html: selected.fullData }}
							></div>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn btn-secondary'
								data-bs-dismiss='modal'
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
