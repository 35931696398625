import React from 'react'

export const Invest = () => {
	return (
		<>
			{/* FAQ */}

			<section
				className='pt-6 pt-md-10 pb-11 pb-md-13 bg-light-boxed-end'
				id='loans2'
			>
				<div className='container-lg'>
					<div className='row align-items-center justify-content-between'>
						<div
							className='col-md-6 text-center text-md-start'
							data-aos='fade-up'
						>
							{/* Heading */}
							<h1 className='display-3 mb-4'>
								Fixed Term Investments. <br />
								<span className='text-primary-light text-underline-primary-light'></span>
							</h1>

							{/* Text */}
							<p className='fs-lg'>
								When you place your hard earned funds with NOLT we deliver
								attractive long/short term returns through an active investment
								process that considers risk, diversification and good yields to
								produce a balanced result.
								<br />
								<br />
								We have an experienced in house Investment team spearheaded by
								Babatunde Dada who is an Investment Specialist with years of
								experience and supported by capable hands.
								<br />
								<br />
								This product encourages you to choose the tenor of your
								investment at a predetermined interest rate. The team at NOLT
								consistently seeks out opportunities in investment through
								meticulous research, locally and globally. The goal is to
								predict feasible financial results in the medium-to long term.
							</p>

							<h3 className='display-4 mb-4'>
								Benefits. <br />
								<span className='text-primary-light text-underline-primary-light'></span>
							</h3>
							<p>
								The Fixed Deposit Account is designed for individuals and
								businesses who want to earn interest on money they’ve put aside
								for a certain period of time. <br />
								<br />A N100,000 minimum opening balance is required.
								<br />
								<br />
								1) Investors will receive a certificate.
								<br />
								2) A 90-day minimum tenor is required.
								<br />
								3) N100,000 or more as a minimum investment.
								<br />
								4) Liquidation before maturity would result in a penalty on the
								interest accrued.
							</p>
							{/* Form */}
							<form className='mb-10 mb-md-0'>
								<div className='input-group rounded-top-start rounded-bottom-end shadow'>
									{/* Input */}

									{/* Prepend */}
								</div>
							</form>
						</div>
						<div className='col-md-5'>
							{/* Slider */}
							<div className='position-relative'>
								{/* Decoration */}
								<div className='position-absolute top-start text-primary-light mt-n8 ms-12'>
									<svg
										width='185'
										height='186'
										viewBox='0 0 185 186'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<circle cx='2' cy='2' r='2' fill='currentColor' />
										<circle cx='22' cy='2' r='2' fill='currentColor' />
										<circle cx='42' cy='2' r='2' fill='currentColor' />
										<circle cx='62' cy='2' r='2' fill='currentColor' />
										<circle cx='82' cy='2' r='2' fill='currentColor' />
										<circle cx='102' cy='2' r='2' fill='currentColor' />
										<circle cx='122' cy='2' r='2' fill='currentColor' />
										<circle cx='142' cy='2' r='2' fill='currentColor' />
										<circle cx='162' cy='2' r='2' fill='currentColor' />
										<circle cx='182' cy='2' r='2' fill='currentColor' />
										<circle cx='2' cy='22' r='2' fill='currentColor' />
										<circle cx='22' cy='22' r='2' fill='currentColor' />
										<circle cx='42' cy='22' r='2' fill='currentColor' />
										<circle cx='62' cy='22' r='2' fill='currentColor' />
										<circle cx='82' cy='22' r='2' fill='currentColor' />
										<circle cx='102' cy='22' r='2' fill='currentColor' />
										<circle cx='122' cy='22' r='2' fill='currentColor' />
										<circle cx='142' cy='22' r='2' fill='currentColor' />
										<circle cx='162' cy='22' r='2' fill='currentColor' />
										<circle cx='182' cy='22' r='2' fill='currentColor' />
										<circle cx='2' cy='42' r='2' fill='currentColor' />
										<circle cx='22' cy='42' r='2' fill='currentColor' />
										<circle cx='42' cy='42' r='2' fill='currentColor' />
										<circle cx='62' cy='42' r='2' fill='currentColor' />
										<circle cx='82' cy='42' r='2' fill='currentColor' />
										<circle cx='102' cy='42' r='2' fill='currentColor' />
										<circle cx='122' cy='42' r='2' fill='currentColor' />
										<circle cx='142' cy='42' r='2' fill='currentColor' />
										<circle cx='162' cy='42' r='2' fill='currentColor' />
										<circle cx='182' cy='42' r='2' fill='currentColor' />
										<circle cx='2' cy='62' r='2' fill='currentColor' />
										<circle cx='22' cy='62' r='2' fill='currentColor' />
										<circle cx='42' cy='62' r='2' fill='currentColor' />
										<circle cx='62' cy='62' r='2' fill='currentColor' />
										<circle cx='82' cy='62' r='2' fill='currentColor' />
										<circle cx='102' cy='62' r='2' fill='currentColor' />
										<circle cx='122' cy='62' r='2' fill='currentColor' />
										<circle cx='142' cy='62' r='2' fill='currentColor' />
										<circle cx='162' cy='62' r='2' fill='currentColor' />
										<circle cx='182' cy='62' r='2' fill='currentColor' />
										<circle cx='2' cy='82' r='2' fill='currentColor' />
										<circle cx='22' cy='82' r='2' fill='currentColor' />
										<circle cx='42' cy='82' r='2' fill='currentColor' />
										<circle cx='62' cy='82' r='2' fill='currentColor' />
										<circle cx='82' cy='82' r='2' fill='currentColor' />
										<circle cx='102' cy='82' r='2' fill='currentColor' />
										<circle cx='122' cy='82' r='2' fill='currentColor' />
										<circle cx='142' cy='82' r='2' fill='currentColor' />
										<circle cx='162' cy='82' r='2' fill='currentColor' />
										<circle cx='182' cy='82' r='2' fill='currentColor' />
										<circle cx='2' cy='102' r='2' fill='currentColor' />
										<circle cx='22' cy='102' r='2' fill='currentColor' />
										<circle cx='42' cy='102' r='2' fill='currentColor' />
										<circle cx='62' cy='102' r='2' fill='currentColor' />
										<circle cx='82' cy='102' r='2' fill='currentColor' />
										<circle cx='102' cy='102' r='2' fill='currentColor' />
										<circle cx='122' cy='102' r='2' fill='currentColor' />
										<circle cx='142' cy='102' r='2' fill='currentColor' />
										<circle cx='162' cy='102' r='2' fill='currentColor' />
										<circle cx='182' cy='102' r='2' fill='currentColor' />
										<circle cx='2' cy='122' r='2' fill='currentColor' />
										<circle cx='22' cy='122' r='2' fill='currentColor' />
										<circle cx='42' cy='122' r='2' fill='currentColor' />
										<circle cx='62' cy='122' r='2' fill='currentColor' />
										<circle cx='82' cy='122' r='2' fill='currentColor' />
										<circle cx='102' cy='122' r='2' fill='currentColor' />
										<circle cx='122' cy='122' r='2' fill='currentColor' />
										<circle cx='142' cy='122' r='2' fill='currentColor' />
										<circle cx='162' cy='122' r='2' fill='currentColor' />
										<circle cx='182' cy='122' r='2' fill='currentColor' />
										<circle cx='2' cy='142' r='2' fill='currentColor' />
										<circle cx='22' cy='142' r='2' fill='currentColor' />
										<circle cx='42' cy='142' r='2' fill='currentColor' />
										<circle cx='62' cy='142' r='2' fill='currentColor' />
										<circle cx='82' cy='142' r='2' fill='currentColor' />
										<circle cx='102' cy='142' r='2' fill='currentColor' />
										<circle cx='122' cy='142' r='2' fill='currentColor' />
										<circle cx='142' cy='142' r='2' fill='currentColor' />
										<circle cx='162' cy='142' r='2' fill='currentColor' />
										<circle cx='182' cy='142' r='2' fill='currentColor' />
										<circle cx='2' cy='162' r='2' fill='currentColor' />
										<circle cx='22' cy='162' r='2' fill='currentColor' />
										<circle cx='42' cy='162' r='2' fill='currentColor' />
										<circle cx='62' cy='162' r='2' fill='currentColor' />
										<circle cx='82' cy='162' r='2' fill='currentColor' />
										<circle cx='102' cy='162' r='2' fill='currentColor' />
										<circle cx='122' cy='162' r='2' fill='currentColor' />
										<circle cx='142' cy='162' r='2' fill='currentColor' />
										<circle cx='162' cy='162' r='2' fill='currentColor' />
										<circle cx='182' cy='162' r='2' fill='currentColor' />
										<circle cx='2' cy='182' r='2' fill='currentColor' />
										<circle cx='22' cy='182' r='2' fill='currentColor' />
										<circle cx='42' cy='182' r='2' fill='currentColor' />
										<circle cx='62' cy='182' r='2' fill='currentColor' />
										<circle cx='82' cy='182' r='2' fill='currentColor' />
										<circle cx='102' cy='182' r='2' fill='currentColor' />
										<circle cx='122' cy='182' r='2' fill='currentColor' />
										<circle cx='142' cy='182' r='2' fill='currentColor' />
										<circle cx='162' cy='182' r='2' fill='currentColor' />
										<circle cx='182' cy='182' r='2' fill='currentColor' />
									</svg>{' '}
								</div>

								{/* Slider */}
								<div>
									<div className='w-100'>
										{/* Image */}
										<img
											className='img-fluid'
											src='assets/img/investNow.jpg'
											alt='...'
											data-aos='wipe-right'
											data-aos-delay='100'
										/>
									</div>
									<div className='w-100'>
										{/* Image */}
										<img
											className='img-fluid'
											src='assets/img/coworking/hero-2.jpg'
											alt='...'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='pt-8 pt-md-9 bg-light' id='lending'>
				<div className='container-lg'>
					<div className='row justify-content-center'>
						<div className='col-md-10 col-lg-8'>
							{/* Heading */}
							<h2
								className='display-4 text-center mb-4'
								style={{ marginTop: '-150px' }}
							>
								Frequently asked questions <br />
								About Your Investments.
							</h2>

							{/* Text */}
							<p className='text-muted text-center mb-9'>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna
								lectus, mattis non accumsan in, tempor dictum neque.
							</p>

							{/* Card */}
							<div
								className='card rounded-top-start-3 rounded-bottom-end-3'
								style={{ zIndex: 1 }}
							>
								<div className='card-body'>
									{/* List group */}
									<div
										className='list-group list-group-lg list-group-flush my-n7'
										id='faq'
									>
										<div className='list-group-item'>
											{/* Heading */}
											<a
												className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
												data-bs-toggle='collapse'
												href='#faqOne'
												role='button'
												aria-expanded='true'
												aria-controls='faqOne'
											>
												What is a fixed term investment product?
											</a>

											{/* Collapse */}
											<div
												className='collapse show'
												id='faqOne'
												data-bs-parent='#faq'
											>
												<p className='mt-2 mb-0'>
													A fixed term investment is a savings option which
													helps you earn interest on your money parked with NOLT
													Finance at periodic intervals either through simple or
													compounded interest on the principled amount. The
													rates of interest are typically higher than money
													parked in savings accounts owing to the fact that the
													money is locked in for a specific period and cannot be
													withdrawn at will of the depositor, except in certain
													scenarios in which customer is ready to bear the
													prematurity penalty
												</p>
											</div>
										</div>
										<div className='list-group-item'>
											{/* Heading */}
											<a
												className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
												data-bs-toggle='collapse'
												href='#faqTwo'
												role='button'
												aria-expanded='false'
												aria-controls='faqTwo'
											>
												Who can invest in NOLT fixed term investment product?
											</a>

											{/* Collapse */}
											<div
												className='collapse'
												id='faqTwo'
												data-bs-parent='#faq'
											>
												<p className='mt-2 mb-0'>
													Individuals, Companies, Body of Individuals,
													Associations of Persons, Societies, Trusts, Sole
													Proprietorships, Partnerships, Clubs, Schools,
													Universities, etc.
												</p>
											</div>
										</div>
										<div className='list-group-item'>
											{/* Heading */}
											<a
												className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
												data-bs-toggle='collapse'
												href='#faqThree'
												role='button'
												aria-expanded='false'
												aria-controls='faqThree'
											>
												What do I need to qualify for Fixed Term Investment?
											</a>

											{/* Collapse */}
											<div
												className='collapse'
												id='faqThree'
												data-bs-parent='#faq'
											>
												<p className='mt-2 mb-0'>
													Fill out a KYC form and provide the required documents
												</p>
											</div>
										</div>
										<div className='list-group-item'>
											{/* Heading */}
											<a
												className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
												data-bs-toggle='collapse'
												href='#faqFour'
												role='button'
												aria-expanded='false'
												aria-controls='faqFour'
											>
												Is there a minimum amount or tenure for a fixed term
												investment?
											</a>

											{/* Collapse */}
											<div
												className='collapse'
												id='faqFour'
												data-bs-parent='#faq'
											>
												<p className='mt-2 mb-0'>
													For a fixed term investment the minimum amount is
													N100,000. Tenures range quarterly from 3 months to 1
													year.
												</p>
											</div>
										</div>

										<div className='list-group-item'>
											{/* Heading */}
											<a
												className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
												data-bs-toggle='collapse'
												href='#faqFour1'
												role='button'
												aria-expanded='false'
												aria-controls='faqFour1'
											>
												If I choose to reinvest my term deposit at the end of
												the tenure, will the interest rate stay the same?{' '}
											</a>

											{/* Collapse */}
											<div
												className='collapse'
												id='faqFour1'
												data-bs-parent='#faq'
											>
												<p className='mt-2 mb-0'>
													Not necessarily. The prevailing interest rate based on
													the tenure of the new deposit will apply.{' '}
												</p>
											</div>
										</div>

										<div className='list-group-item'>
											{/* Heading */}
											<a
												className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
												data-bs-toggle='collapse'
												href='#faqFour2'
												role='button'
												aria-expanded='false'
												aria-controls='faqFour2'
											>
												Under what regulations are the fixed term investment
												accepted by NOLT governed?
											</a>
											{/* Collapse */}
											<div
												className='collapse'
												id='faqFour2'
												data-bs-parent='#faq'
											>
												<p className='mt-2 mb-0'>CBN</p>{' '}
											</div>
										</div>

										<div className='list-group-item'>
											{/* Heading */}
											<a
												className='collapse-toggle fs-lg fw-bold text-decoration-none text-reset'
												data-bs-toggle='collapse'
												href='#faqFour3'
												role='button'
												aria-expanded='false'
												aria-controls='faqFour3'
											>
												Can I terminate my fixed term investment before the
												maturity date?
											</a>
											{/* Collapse */}
											<div
												className='collapse'
												id='faqFour3'
												data-bs-parent='#faq'
											>
												<p className='mt-2 mb-0'>
													Yes you can but there are penalties for early closure.
												</p>{' '}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* SHAPE */}
			<div className='position-relative'>
				<div className='shape shape-fluid-x shape-bottom text-light pb-15 pb-md-10'>
					<div className='shape-img pb-9 pb-md-15'>
						<svg viewBox='0 0 100 50' preserveAspectRatio='none'>
							<path d='M0 0h100v25H75L25 50H0z' fill='currentColor' />
						</svg>{' '}
					</div>
				</div>
			</div>

			{/* TESTIMONIALS */}
			<br />
			<br />

			{/* BRANDS */}

			{/* CONTACT */}

			{/* CTA */}
			<section className='bg-dark border-multicolor'>
				<div className='container-lg'>
					<div className='row align-items-center'>
						<div className='col-12'>
							{/* Divider */}
							<hr className='bg-gray-900 mt-0 mb-8' />
						</div>
						<div className='col-md'>
							{/* Heading */}
							<h1 className='text-white mb-0'>
								Ready to get a Loan to meet your needs?{' '}
								<span className='text-primary-light'></span>
							</h1>

							{/* Text */}
							<p className='text-white-60 mb-4 mb-md-0'>
								We’ll walk you through the loan application process.
							</p>
						</div>
						<div className='col-md-auto'>
							{/* Button */}
							<a className='btn btn-primary lift' href='/invest#loans'>
								Apply Now
							</a>
						</div>
						<div className='col-12'>
							{/* Divider */}
							<hr className='bg-gray-900 mb-0 mt-8' />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
